import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import 'antd/dist/reset.css';
// import "./index.scss";
import * as Pages from './pages';
import { UserProvider } from 'context/TokenContext';
import { HousingProvider } from 'context/HousingContext';
import { ModalProvider } from 'context/ModalContext';
import ProtectedRoute from 'components/ProtectedRoute';
import { useEffect } from 'react';
import { IS_PRODUCTION } from 'utils/constants';

export default function App() {
  useEffect(() => {
    const midtransScriptUrl = IS_PRODUCTION ? 'https://app.midtrans.com/snap/snap.js' : 'https://app.sandbox.midtrans.com/snap/snap.js';

    const scriptTag = document.createElement('script');
    scriptTag.src = midtransScriptUrl;

    const myMidtransClientKey = process.env.MIDTRANS_CLIENT_KEY as string;
    scriptTag.setAttribute('data-client-key', myMidtransClientKey);

    document.body.appendChild(scriptTag);

    return () => {
      document.body.removeChild(scriptTag);
    };
  }, []);

  const router = createBrowserRouter([
    {
      path: '/',
      element: <Pages.PublicDashboard />
    },
    {
      path: '/calculator',
      element: <Pages.CalculatorPage />
    },
    {
      path: '/payment-steps',
      element: <Pages.PaymentStepsPage />
    },
    {
      element: <Pages.CenteredLayout />,
      children: [
        {
          path: '/login',
          element: <Pages.LoginPage />
        },
        {
          path: '/register',
          element: <Pages.RegisterPage />
        }
      ]
    },
    {
      path: '/admin',
      element: (
        <ProtectedRoute fallback="/login">
          <Pages.HomePage />
        </ProtectedRoute>
      )
    },
    {
      path: '/admin/customerList',
      element: (
        <ProtectedRoute fallback="/login">
          <ModalProvider>
            <Pages.CustomerListPage />
          </ModalProvider>
        </ProtectedRoute>
      )
    },
    {
      path: '/admin/usage/:thisYear',
      element: (
        <ProtectedRoute fallback="/login">
          <Pages.UsagePage />
        </ProtectedRoute>
      )
    },
    {
      path: '/admin/usage/:selectedYear/:selectedMonth',
      element: (
        <ProtectedRoute fallback="/login">
          <ModalProvider>
            <Pages.UsageDetailPage />
          </ModalProvider>
        </ProtectedRoute>
      )
    },
    {
      path: '/admin/invoiceList',
      element: (
        <ProtectedRoute fallback="/login">
          <Pages.InvoiceListPage />;
        </ProtectedRoute>
      )
    },
    {
      path: '/admin/debtList',
      element: (
        <ProtectedRoute fallback="/login">
          <Pages.DebtPage />;
        </ProtectedRoute>
      )
    },
    {
      element: <Pages.CustomerHeader />,
      children: [
        {
          path: '/customer',
          element: <Pages.LandingPage />
        },
        {
          path: '/customer/invoiceDetail',
          element: <Pages.InvoiceDetail />
        },
        {
          path: '/customer/payment',
          element: <Pages.StatusPage />
        }
      ]
    },
    {
      path: '/*',
      element: <Pages.NotFound />
    }
  ]);

  return (
    <UserProvider>
      <HousingProvider>
        <RouterProvider router={router} />
      </HousingProvider>
    </UserProvider>
  );
}
