import { useRef } from 'react';
import { Button, Modal, Space } from 'antd';

export const IframeModal = ({
  htmlContent,
  isModalOpen,
  setIsModalOpen
}: {
  htmlContent: string;
  isModalOpen: boolean;
  setIsModalOpen: (boolean) => void;
}) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const printIframeContent = () => {
    if (iframeRef.current) {
      iframeRef.current.focus();
      iframeRef.current.contentWindow?.print();
    }
  };

  return (
    <Modal
      width='750px'
      open={isModalOpen}
      onCancel={handleCancel}
      footer={null}
    >
      <iframe
        srcDoc={htmlContent}
        title="Modal"
        ref={iframeRef}
        className="modal-iframe"
        style={{ width: '100%', height: '500px' }}
      />
      <Space
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: 10,
          marginTop: 20
        }}
      >
        <Button
          onClick={() => {
            setIsModalOpen(false);
          }}
        >
          Close
        </Button>
        <Button type="primary" onClick={printIframeContent}>
          Print
        </Button>
      </Space>
    </Modal>
  );
};
