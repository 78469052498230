import {
  GetInvoiceStatisticsResponse,
  GetInvoicesResponse,
  params
} from 'interfaces/api';
import { api } from 'utils/api';

export const getInvoiceStatistics = async (
  params: params
): Promise<GetInvoiceStatisticsResponse> => {
  const apiUrl = '/invoices/statistics';
  const response = (await api.get(apiUrl, { params }))
    .data as GetInvoiceStatisticsResponse;
  return response;
};

export const getInvoices = async (
  params: params
): Promise<GetInvoicesResponse> => {
  const apiUrl = '/invoices/';
  const response = (await api.get(apiUrl, { params }))
    .data as GetInvoicesResponse;
  return response;
};

export const getInvoicePDF = async (id: number): Promise<string> => {
  const response = (await api.get(`/invoices/render/${id}`)).data as string;
  return response;
};
