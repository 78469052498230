import { GetCustomerResponse } from 'interfaces/api';
import { CustomerData } from 'interfaces/data';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { getCustomer } from 'services/customer';

export const useCustomer = (token: string | null) => {
  const [customer, setCustomer] = useState<CustomerData>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  useEffect(() => {
    setIsLoading(true);
    async function fetchCustomer() {
      if (token) {
        try {
          const customerData = (await getCustomer(
            token
          )) as GetCustomerResponse;
          setCustomer(customerData.customer);
        } catch (err) {
          navigate('/notfound');
        }
      }
      setIsLoading(false);
    }
    fetchCustomer();
  }, [token]);

  return { customer, isLoading };
};
