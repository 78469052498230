import { VolumeCard } from './VolumeCard';
import { YearFilterBox } from 'components/ui/FilterBox';
import { InvoiceCard } from './InvoiceCard';
import { ActiveCustomerCard } from './ActiveCustomerCard';
import { CustomerInvoices } from './CustomerInvoices';
import { RecentPayments } from './RecentPayments';
export const DashboardContent = () => {
  return (
    <div>
      <YearFilterBox/>
      <div className="column">
        <div className="row">
          <div className="top">
            <VolumeCard />
            <InvoiceCard />
            <ActiveCustomerCard />
          </div>
          <CustomerInvoices />
        </div>
        <RecentPayments />
      </div>
    </div>
  );
};
