import Logo from '../../assets/logo.png';

export const ProfileCard = () => {
  return (
    <div className="flex flex-col items-center p-10 space-x-5 text-center">
      <img src={Logo} alt="logo" style={{ width: '20%' }} />
      <div className="p-10 space-y-4 flex flex-col flex-grow">
        <div className="font-medium text-gray-700 text-lg">
          Mulya Water melayani penyaluran air untuk perumahan:
          <div className="flex flex-row justify-between m-4">
            <div
              className="w-10vw p-2 bg-blue-200 bg-opacity-30 backdrop-blur-xl text-black rounded-xl shadow-md text-lg text-center"
              style={{ width: '30%' }}
            >
              Pondok Mulya <br /> Melatiwangi
            </div>
            <div
              className="w-10vw p-2 bg-blue-200 bg-opacity-30 backdrop-blur-xl text-black rounded-xl shadow-md text-lg text-center"
              style={{ width: '30%' }}
            >
              Pesona Mulya <br /> Residence
            </div>
            <div
              className="w-10vw p-2 bg-blue-200 bg-opacity-30 backdrop-blur-xl text-black rounded-xl shadow-md text-lg text-center"
              style={{ width: '30%' }}
            >
              Pasir Jati <br /> Indah
            </div>
          </div>
        </div>
        <div>
          <p className="font-medium text-gray-700">
            <span className="font-bold">Alamat:</span> Pondok Mulya Melatiwangi
            Blok C No 14, Melatiwangi, Cilengkrang, Bandung Regency, Jawa Barat,
            40618, Indonesia
          </p>
          <p className="font-medium text-gray-700">
            <span className="font-bold">Contact Person:</span> 081221159697
            (Mulya Water)
          </p>
        </div>
      </div>
    </div>
  );
};
