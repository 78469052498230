import React, { ReactElement, createContext, useContext, useEffect, useState } from 'react';
import { api } from 'utils/api';

interface TokenContextValue {
  login: boolean;
  loading: boolean;
  setLogin: React.Dispatch<React.SetStateAction<boolean>>;
}

export const TokenContext = createContext<TokenContextValue>({
  login: false,
  loading: true,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setLogin: () => {}
});

const checkToken = async () => {
  try {
    await api.get('/customers');
    return true;
  } catch (err: any) {
    return false;
  }
};

export const UserProvider = ({ children }: { children: ReactElement }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [login, setLogin] = useState<boolean>(false);
  useEffect(() => {
    const check = async () => {
      const token = await checkToken();
      setLogin(token);
      setLoading(false);
    };
    check();
  }, [login]);

  return (
    <TokenContext.Provider value={{ login, loading, setLogin }}>
      {children}
    </TokenContext.Provider>
  );
};

export const useToken = () => useContext(TokenContext);
