import Logo from '../../assets/logo.png';
import { Outlet } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useCustomer } from 'hooks/useCustomer';
import { HOUSINGS } from 'utils/constants';

const CustomerHeader = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const { customer, isLoading } = useCustomer(token);

  return (
    <>
      {!isLoading && customer && (
        <>
          <header className="bg-[#1E1E1E]">
            <div className="header">
              <div className="housing">
                <img src={Logo} alt="logo" style={{ width: 40 }} />
                <div style={{ color: 'white', marginLeft: '10px' }}>
                  {HOUSINGS[customer.housing].name}
                </div>
              </div>
              <div className="name">
                <div style={{ color: 'white' }}>
                  {customer?.name}/{customer.blockNumber}
                </div>
              </div>
            </div>
          </header>
          <Outlet />
        </>
      )}
    </>
  );
};

export default CustomerHeader;
