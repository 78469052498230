export const formatCurrency = (amount: number): string => {
  return new Intl.NumberFormat('id-ID', {
    style: 'currency',
    currency: 'IDR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(amount);
};

export const formatTitle = (title: string): string => {
  const words = title.split('_');
  const capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  );
  return capitalizedWords.join(' ');
};

export const splitBlockNumber = (blockNumber: string) => {
  const temp = blockNumber.split('-');
  return { block: temp[0], blockNumber: temp[1] || '' };
};

export const getBlockNumber = (block: string, num: string) => {
  return `${block}-${num}`;
};
