import { Breadcrumb } from 'antd';
import { DebtList } from 'components/Debt/DebtList';
import { ModalProvider } from 'context/ModalContext';
import React from 'react';

const DebtPage: React.FC = () => {
  return (
    <ModalProvider>
      <div className="content">
        <div className="breadcrumb">
          <Breadcrumb style={{ margin: '16px 0' }}>
            <Breadcrumb.Item>Utang</Breadcrumb.Item>
            <Breadcrumb.Item>Daftar Utang</Breadcrumb.Item>
          </Breadcrumb>
          <p>Daftar Utang</p>
        </div>
        <div className="list">
          <DebtList />
        </div>
      </div>
    </ModalProvider>
  );
};

export default DebtPage;
