import { Steps } from 'antd';

const { Step } = Steps;

const steps = [
  {
    title: 'Scan QR',
    description:
      'Masuk ke dalam website dengan cara meng-scan QR yang telah diberikan oleh petugas (Apabila QR hilang, dapat datang ke kantor pemasaran / pengembang di Blok C No.14 untuk meminta QR baru)'
  },
  {
    title: 'Lihat Total Tagihan',
    description:
      "Total tagihan dapat dilihat dalam website, beserta rinciannya setiap bulan. Rincian tagihan secara detail setiap bulan dapat diakses dengan mengklik tombol 'Lihat Detail'."
  },
  {
    title: 'Bayar',
    description:
      "Bayar tagihan dengan mengklik tombol 'Bayar'. Pilih metode pembayaran dengan GoPay / QRIS."
  },
  {
    title: 'Cek Status Pembayaran',
    description:
      'Periksa bahwa tagihan berhasil dibayar. Dapat dicek dalam halaman total tagihan.\n.'
  }
];

export const PaymentSteps = () => {
  return (
    <div className="p-20">
      <p className="text-2xl font-bold mb-6">Cara Bayar</p>
      <Steps direction="vertical" className="text-black" current={-1}>
        {steps.map((step, index) => (
          <Step
            key={index}
            title={<span className="font-bold text-black">{step.title}</span>}
            description={
              <span className="text-gray-800">{step.description}</span>
            }
          />
        ))}
      </Steps>
    </div>
  );
};
