import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import axios, { AxiosError } from 'axios';
import { useToken } from 'context/TokenContext';
import { ErrorResponse } from 'interfaces/api';
import { AuthFormData } from 'interfaces/auth';
import { login, register } from 'services/auth';
import 'styles/login.scss';
import { DISABLE_REGISTER } from 'utils/constants';

interface AuthFormProps {
  title: 'login' | 'register';
}

export const AuthForm = ({ title }: AuthFormProps) => {
  const formattedTitle = title === 'login' ? 'Login' : 'Daftar';
  const opposite = title === 'login' ? 'daftar' : 'login';
  const { setLogin } = useToken();

  const handleAuth = async (data: AuthFormData) => {
    try {
      await (title === 'login' ? login(data) : register(data));
      setLogin(true);
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const error = err as AxiosError<ErrorResponse>;
        alert(error.response?.data.message);
      }
    }
  };

  return (
    <div className="login-container">
      <Form name="normal_login" className="login-form" onFinish={handleAuth}>
        <h1 className="login-title">{formattedTitle}</h1>
        <Form.Item
          name="username"
          rules={[{ required: true, message: 'Mohon masukkan username Anda!' }]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Username"
          />
        </Form.Item>
        <Form.Item
          name="password"
          className="password-form-item"
          rules={[{ required: true, message: 'Mohon masukkan password Anda!' }]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            {formattedTitle}
          </Button>
        </Form.Item>
        {!DISABLE_REGISTER && (
          <div className="redirect-auth">
            Atau <a href={`/${title === 'login' ? 'register' : 'login'}`}>{opposite} sekarang!</a>
          </div>
        )}
      </Form>
    </div>
  );
};
