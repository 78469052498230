import { Modal } from 'antd';
import { formatTitle } from 'utils';
import { useModal } from 'context/ModalContext';
import React from 'react';

export const CustomModal = ({
  title,
  children
}: {
  title: string;
  children: React.ReactNode;
}) => {
  const { isModalOpen, setIsModalOpen } = useModal();
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <Modal
      title={`${formatTitle(title)}`}
      width="42vw"
      open={isModalOpen}
      onCancel={handleCancel}
      footer={null}
    >
      {children}
    </Modal>
  );
};
