import {
  AddUsageRequest,
  GetUsageResponse,
  GetUsagesResponse,
  GetYearlyUsagesResponse,
  UpdateUsageRequest,
  usageParams
} from 'interfaces/api';
import { api } from 'utils/api';

export const getUsageByCustomerId = async (
  params: usageParams
): Promise<GetUsageResponse> => {
  const apiUrl = '/usages/single';
  const response = (await api.get(apiUrl, { params })).data as GetUsageResponse;
  return response;
};

export const getUsages = async (
  params: usageParams
): Promise<GetUsagesResponse> => {
  const apiUrl = 'usages/';
  const response = (await api.get(apiUrl, { params }))
    .data as GetUsagesResponse;
  return response;
};

export const getYearlyUsages = async (
  params: usageParams
): Promise<GetYearlyUsagesResponse> => {
  const apiUrl = 'usages/yearly-statistics/';
  const response = (await api.get(apiUrl, { params }))
    .data as GetYearlyUsagesResponse;
  return response;
};

export const addUsage = async (usage: AddUsageRequest) => {
  return api.post('/usages', usage);
};

export const updateUsage = async (usage: UpdateUsageRequest) => {
  return api.put('/usages', usage);
};
