import { Breadcrumb } from 'antd';
import PropTypes from 'prop-types';

export const PageTitle = ({ title }) => {
  return (
    <div className="breadcrumb">
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>{title}</Breadcrumb.Item>
      </Breadcrumb>
      <p>{title}</p>
    </div>
  );
};

PageTitle.propTypes = {
  title: PropTypes.string.isRequired
};
