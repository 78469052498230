import React, {
  ReactElement,
  createContext,
  useContext,
  useEffect,
  useState
} from 'react';

interface ModalContextValue {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ModalContext = createContext<ModalContextValue>({
  isModalOpen: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setIsModalOpen: () => {}
});

export const ModalProvider = ({ children }: { children: ReactElement }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  useEffect(() => {
    setIsModalOpen(false);
  }, []);

  return (
    <ModalContext.Provider value={{ isModalOpen, setIsModalOpen }}>
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = () => useContext(ModalContext);
