import { Button, Spin, Table } from 'antd';
import { InvoiceStatusTag } from 'components/ui/Tag';
import { useHousing } from 'context/HousingContext';
import { useYear } from 'context/YearContext';
import { usePaging } from 'hooks/usePaging';
import { params } from 'interfaces/api';
import { CustomerWithInvoice, InvoiceData } from 'interfaces/data';
import { useState, useEffect, useMemo } from 'react';
import {
  getCustomersWithInvoices,
  renderCustomerInvoices
} from 'services/customer';
import { PrinterOutlined } from '@ant-design/icons';
import { MONTHS } from 'utils/constants';
import moment from 'moment';

export const CustomerInvoices = () => {
  const { housing } = useHousing();
  const { year } = useYear();
  const { page, pageSize, handlePageChange } = usePaging();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [customers, setCustomers] = useState<CustomerWithInvoice[]>();
  const [totalCustomer, setTotalCustomer] = useState<number>(0);

  useEffect(() => {
    const fetchInvoices = async () => {
      try {
        setIsLoading(true);
        const params: params = {
          year,
          status: JSON.stringify(['ACTIVE', 'AWAITING_SUSPENSION']),
          sort: 'createdAt,desc',
          page,
          take: pageSize
        };
        if (housing !== 'ALL_HOUSING') params.housing = housing;
        const response = await getCustomersWithInvoices(params);
        setCustomers(response.customers);
        setTotalCustomer(response.customersCount);
        setIsLoading(false);
      } catch (err) {
        console.log(err);
      }
    };
    fetchInvoices();
  }, [housing, page, pageSize, year]);

  const tableData = useMemo(
    () =>
      customers?.map((customer: CustomerWithInvoice) => {
        const data: InvoiceData[] = new Array(12).fill(null);

        customer.Invoice.forEach(invoice => {
          const month = moment(invoice.date).month();

          data[month] = invoice;
        });

        return {
          key: customer.id,
          customerName: customer.name,
          blockNumber: customer.blockNumber,
          invoice: data
        };
      }),
    [customers]
  );

  const printCustomerInvoices = async (takeAll = false) => {
    try {
      const params: params = {
        year,
        status: JSON.stringify(['ACTIVE', 'AWAITING_SUSPENSION']),
        sort: 'createdAt,desc',
        page: takeAll ? 1 : page,
        take: takeAll ? -1 : pageSize
      };
      if (housing !== 'ALL_HOUSING') params.housing = housing;
      const htmlContent = await renderCustomerInvoices(params);
      const printWindow = window.open('', '_blank');
      if (printWindow?.document) {
        printWindow.document.open();
        printWindow.document.write(htmlContent);
        printWindow.document.close();
        printWindow.print();
        printWindow.onafterprint = function() {
          printWindow.document.close();
        };
      }
    } catch (err) {
      console.log(err);
    }
  };

  const PrintInvoicesButton = () => {
    return (
      <>
        <Button icon={<PrinterOutlined />} onClick={() => printCustomerInvoices(false)}>
          Print halaman ini
        </Button>
        <Button icon={<PrinterOutlined />} onClick={() => printCustomerInvoices(true)}>
          Print semua
        </Button>
      </>
    );
  };

  const columns: any = [
    {
      title: 'Nama Pelanggan',
      key: 'customerName',
      dataIndex: 'customerName',
      width: 170,
      fixed: 'left',
      ellipsis: true
    },
    {
      title: 'Blok',
      key: 'blockNumber',
      dataIndex: 'blockNumber',
      width: 100,
      fixed: 'left',
      ellipsis: true
    },
    ...MONTHS.map((month, index) => ({
      title: month.title,
      dataIndex: 'invoice',
      render: (record: InvoiceData[]) => {
        const invoiceItem = record[index];
        if (!invoiceItem) return <div />;
        return (
          <InvoiceStatusTag
            status={invoiceItem?.status}
            totalAmount={invoiceItem?.totalAmount}
          />
        );
      }
    }))
  ];

  return (
    <div className="usages">
      <Spin spinning={isLoading}>
        <Table
          dataSource={tableData}
          pagination={{
            position: ['bottomCenter'],
            responsive: true,
            pageSizeOptions: [10, 20],
            showSizeChanger: true,
            total: totalCustomer,
            onChange: (num, size) => handlePageChange(num, size)
          }}
          columns={columns}
          scroll={{ x: 1500 }}
          footer={PrintInvoicesButton}
        />
      </Spin>
    </div>
  );
};
