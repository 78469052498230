import { PublicLayout } from '../components/layout/PublicLayout';
import { ProfileCard } from '../components/Public/ProfileCard';

const PublicDashboard = () => {
  return (
    <PublicLayout>
      <ProfileCard />
    </PublicLayout>
  );
};

export default PublicDashboard;
