import { CalculatorCard } from 'components/Public/CalculatorCard';
import { PublicLayout } from 'components/layout/PublicLayout';

const CalculatorPage = () => {
  return (
    <PublicLayout>
      <CalculatorCard />
    </PublicLayout>
  );
};

export default CalculatorPage;
