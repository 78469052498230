import axios, { AxiosError } from 'axios';
import {
  AddCustomerRequest,
  ErrorResponse,
  GetCustomerResponse,
  GetCustomersCollectionResponse,
  GetCustomersInvoiceResponse,
  GetCustomersResponse,
  SendWAResponse,
  UpdateCustomerRequest,
  params,
  usageParams
} from 'interfaces/api';
import { api } from 'utils/api';

export const getCustomer = async (
  token: string
): Promise<GetCustomerResponse | ErrorResponse> => {
  try {
    const response = await api.get(`/public/customer?token=${token}`);
    const data = response.data as GetCustomerResponse;
    return data;
  } catch (err: unknown) {
    if (axios.isAxiosError(err)) {
      const error = err as AxiosError<ErrorResponse>;
      if (error.response) throw error.response.data;
    }
    throw err;
  }
};

export const getCustomers = async (
  params: params
): Promise<GetCustomersResponse> => {
  const apiUrl = '/customers';
  const response = (await api.get(apiUrl, { params }))
    .data as GetCustomersResponse;
  return response;
};

export const getCustomersWithInvoices = async (
  params: params
): Promise<GetCustomersInvoiceResponse> => {
  const apiUrl = '/customers/invoices';
  const response = (await api.get(apiUrl, { params }))
    .data as GetCustomersInvoiceResponse;
  return response;
};

export const getCustomersByUsage = async (
  params: usageParams
): Promise<GetCustomersResponse> => {
  const apiUrl = '/customers/usages';
  const response = (await api.get(apiUrl, { params }))
    .data as GetCustomersResponse;
  return response;
};

export const addCustomer = async (customer: AddCustomerRequest) => {
  return api.post('/customers', customer);
};

export const updateCustomer = async (customer: UpdateCustomerRequest) => {
  return api.put('/customers', customer);
};

export const getCollections = async (
  params: params
): Promise<GetCustomersCollectionResponse> => {
  const apiUrl = 'customers/collections/';
  const response = (await api.get(apiUrl, { params }))
    .data as GetCustomersCollectionResponse;
  return response;
};

export const sendWA = async (id: number): Promise<SendWAResponse> => {
  const res = (await api.get(`/customers/whatsapp/${id}`))
    .data as SendWAResponse;
  return res;
};

export const getQR = async (id: number): Promise<string> => {
  const res = (await api.get(`/customers/generate-qr-html/${id}`))
    .data as string;
  return res;
};

export const renderCustomerInvoices = async (
  params: params
): Promise<string> => {
  const res = (await api.get('/customers/invoices/render', { params }))
    .data as string;
  return res;
};
