import {
  AddPaymentRequest,
  AddPaymentResponse,
  GetPaymentsResponse,
  params
} from 'interfaces/api';
import { api } from 'utils/api';

export const getPayments = async (
  params: params
): Promise<GetPaymentsResponse> => {
  const apiUrl = '/payments';
  const response = (await api.get(apiUrl, { params }))
    .data as GetPaymentsResponse;
  return response;
};

export const addPayment = async (
  collectionId: AddPaymentRequest
): Promise<AddPaymentResponse> => {
  const apiUrl = '/payments';
  const response = (await api.post(apiUrl, collectionId)).data as AddPaymentResponse;
  return response;
};

export const addManualPayment = async(
  collectionId: AddPaymentRequest
): Promise<AddPaymentResponse> => {
  const apiUrl = '/payments/manual';
  const response = (await api.post(apiUrl, collectionId)).data as AddPaymentResponse;
  return response;
};

// const getCustomersCount = async () => {
//   if (housing === 'ALL_HOUSING') {
//     console.log('all housing');
//     const response = await api.get(
//       '/customers/?status=["ACTIVE","AWAITING_SUSPENSION"]'
//     );
//     setCustomerCount(response.data.customersCount);
//     setIsCustomerLoading(false);
//   }
//   if (
//     housing === 'PESONA_MULYA_RESIDENCE' ||
//     housing === 'PONDOK_MULYA_MELATIWANGI' ||
//     housing === 'PASIR_JATI_INDAH'
//   ) {
//     console.log('not all housing');
//     const response = await api.get(
//       `/customers/?housing=${housing}&status=["ACTIVE","AWAITING_SUSPENSION"]`
//     );
//     setCustomerCount(response.data.customersCount);
//     setIsCustomerLoading(false);
//   }
// };

// const getInvoiceStatistics = async () => {
//   setIsTotalInvoiceLoading(true);
//   if (housing === 'ALL_HOUSING') {
//     const response = await api.get(`/invoices/statistics/?year=${year}`);
//     console.log(response.data);
//     setTotalPaid(response.data.totalAmountPaid);
//     setTotalUnpaid(response.data.totalAmountUnpaid);
//     setTotalAll(response.data.totalAmount);
//     setIsTotalInvoiceLoading(false);
//   }
//   if (
//     housing === 'PESONA_MULYA_RESIDENCE' ||
//     housing === 'PONDOK_MULYA_MELATIWANGI' ||
//     housing === 'PASIR_JATI_INDAH'
//   ) {
//     const response = await api.get(
//       `/invoices/statistics/?year=${year}&housing=${housing}`
//     );
//     console.log(response.data);
//     setTotalPaid(response.data.totalAmountPaid);
//     setTotalUnpaid(response.data.totalAmountUnpaid);
//     setTotalAll(response.data.totalAmount);
//     setIsTotalInvoiceLoading(false);
//   }
// };
