import React, {
  ReactElement,
  createContext,
  useContext,
  useEffect,
  useState
} from 'react';

interface YearContextValue {
  year: number;
  setYear: React.Dispatch<React.SetStateAction<number>>;
}

export const YearContext = createContext<YearContextValue>({
  year: new Date().getFullYear(),
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setYear: () => {}
});

export const YearProvider = ({ children }: { children: ReactElement }) => {
  const [year, setYear] = useState<number>(new Date().getFullYear());

  useEffect(() => {
    setYear(new Date().getFullYear());
  }, []);

  console.log('YearProvider:', year); // Add this line
  return (
    <YearContext.Provider value={{ year, setYear }}>
      {children}
    </YearContext.Provider>
  );
};

export const useYear = () => useContext(YearContext);
