/* eslint-disable no-unused-vars */

export const IS_PRODUCTION = true;

export const IS_DEBUG_MODE = false;

export const BACKEND_URL = IS_PRODUCTION ? 'https://api.mulyawater.com' : 'http://localhost:3000';

export const MIDTRANS_CLIENT_KEY = IS_PRODUCTION ? 'Mid-client-fF8mqynSQbvkWx6-' : 'SB-Mid-client-rH3s4g6RRbc5p2y3';

export type HousingType =
  | 'PASIR_JATI_INDAH'
  | 'PONDOK_MULYA_MELATIWANGI'
  | 'PESONA_MULYA_RESIDENCE';

interface Housing {
  block: string[];
  name: string;
}

interface Month {
  title: string;
  key: string;
  index: number;
}

export const HOUSINGS: Record<HousingType, Housing> = {
  PONDOK_MULYA_MELATIWANGI: {
    name: 'Pondok Mulya Melatiwangi',
    block: [
      'A',
      'B',
      'C',
      'D',
      'E',
      'F',
      'G',
      'H',
      'I',
      'J',
      'K',
      'L',
      'M',
      'N',
      'O',
      'P',
      'Q',
      'R',
      'S',
      'T',
      'U',
      'V',
      'W'
    ]
  },
  PESONA_MULYA_RESIDENCE: {
    name: 'Pesona Mulya Residence',
    block: ['K1', 'K2']
  },
  PASIR_JATI_INDAH: {
    name: 'Pasir Jati Indah',
    block: ['G3', 'H3', 'H4', 'H5', 'H6']
  }
};

export const MONTHS = (() => {
  const months: Month[] = [];

  for (let monthIndex = 0; monthIndex < 12; monthIndex++) {
    const monthDate = new Date(0, monthIndex);
    const monthName = monthDate.toLocaleString('id-ID', { month: 'long' });

    const monthObject = {
      title: monthName,
      key: monthName.toLowerCase(),
      index: monthIndex
    };

    months.push(monthObject);
  }

  return months;
})();

export enum PaymentStatus {
  PENDING = 'PENDING',
  PAID = 'PAID',
  CANCELLED = 'CANCELLED',
  EXPIRED = 'EXPIRED',
  REFUNDED = 'REFUNDED'
}

export enum PaymentMethod {
  GOPAY = 'GOPAY',
  QRIS = 'QRIS',
  MANUAL = 'MANUAL'
}

export enum InvoiceStatus {
  AWAITING_PAYMENT = 'AWAITING_PAYMENT',
  PAID = 'PAID',
  CLOSED = 'CLOSED',
  REFUNDED = 'REFUNDED'
}

export enum CustomerStatus {
  AWAITING_SUSPENSION = 'AWAITING_SUSPENSION',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}

export const StatusText = {
  AWAITING_SUSPENSION: 'Menunggu Penangguhan',
  ACTIVE: 'Aktif',
  INACTIVE: 'Nonaktif',
  AWAITING_PAYMENT: 'Menunggu Pembayaran',
  PAID: 'Terbayar',
  CLOSED: 'Ditutup',
  REFUNDED: 'Dikembalikan',
  PENDING: 'Pending',
  CANCELLED: 'Dibatalkan',
  EXPIRED: 'Kadaluarsa'
};

export const DISABLE_REGISTER = true;
