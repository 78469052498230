import React, { useEffect, useState, useMemo, useRef } from 'react';
import type { InputRef } from 'antd';
import { Space, Table, Button, message } from 'antd';
import moment from 'moment';
import { useHousing } from 'context/HousingContext';
import { ErrorResponse, params } from 'interfaces/api';
import { usePaging } from 'hooks/usePaging';
import { getCollections, sendWA } from 'services/customer';
import {
  CustomerWithCollection,
  InvoiceData
} from 'interfaces/data';
import { formatCurrency } from 'utils';
import axios, { AxiosError } from 'axios';
import { getColumnSearchProps } from 'components/ui/Table';
import { useFilters } from 'hooks/useFilters';
import { addPayment } from 'services/payment';
import { getInvoicePDF } from 'services/invoices';
import { IframeModal } from 'components/ui/IframeModal';
import { MarkPaidManual } from './MarkPaidManual';

interface DataType {
  key: React.Key;
  id: number;
  customerName: string;
  block: string;
  totalUnpaidAmount: number;
  invoice: InvoiceData[];
}

export const DebtList: React.FC = () => {
  const [total, setTotal] = useState<number>(0);
  const [collections, setCollections] = useState<CustomerWithCollection[]>([]);
  const { page, pageSize, handlePageChange } = usePaging();
  const { housing } = useHousing();
  const searchBlockInput = useRef<InputRef>(null);
  const { filter, updateFilter } = useFilters();
  const [htmlContent, setHtmlContent] = useState<string>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const viewPDF = async (id: number) => {
    try {
      const res = await getInvoicePDF(id);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      setHtmlContent(res);
      setIsModalOpen(true);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const fetchCollections = async () => {
      const params: params = {
        ...filter,
        page,
        take: pageSize
      };
      if (housing !== 'ALL_HOUSING') params.housing = housing;

      const response = await getCollections(params);
      setCollections(response.customers);
      setTotal(response.customersCount);
    };

    fetchCollections();
  }, [housing, page, pageSize, filter]);

  const expandedRowRender = (record: DataType) => {
    console.log(record);
    const columns: any = [
      {
        title: 'Tanggal',
        dataIndex: 'date',
        key: 'date',
        width: '25%',
        render: (d: Date) => {
          return <span>{moment(d).format('MMM/yyyy')}</span>;
        }
      },
      {
        title: 'Total Biaya',
        dataIndex: 'totalAmount',
        key: 'totalAmount',
        width: '25%'
      },
      {
        title: 'Total Penggunaan',
        key: 'totalUsage',
        dataIndex: 'totalUsage',
        width: '25%'
      },
      {
        title: 'Aksi',
        dataIndex: 'invoiceId',
        render: (id: number) => (
          <Space size="middle">
            {/* eslint-disable-next-line @typescript-eslint/no-unsafe-argument */}
            <Button type="link" onClick={() => viewPDF(id)}>
              Lihat PDF
            </Button>
          </Space>
        )
      }
    ];

    const data = record.invoice.map((invoice: InvoiceData) => ({
      key: invoice.id,
      date: invoice.date,
      totalAmount: formatCurrency(invoice.totalAmount),
      totalUsage: invoice.currentVolume,
      invoiceId: invoice.id
    }));

    return <Table columns={columns} dataSource={data} pagination={false} />;
  };

  const handleCreatePayment = async (record: DataType) => {
    try {
      const response = await addPayment({ collectionId: Number(record.key) });
      const token = response.payment.pgToken;
      window.snap.pay(token, {
        onSuccess: function () {
          message.success('Pembayaran berhasil');
        },
        onPending: function () {
          message.success('Pembayaran pending');
        },
        onError: function () {
          message.error('Pembayaran gagal');
        },
        onClose: function () {
          message.info('Pembayaran ditutup');
        }
      });
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        console.log(err);
        const error = err as AxiosError<ErrorResponse>;
        alert(error.response?.data.message);
      }
    }
  };

  const handleSendWA = async (customer: DataType) => {
    const res = await sendWA(customer.id);
    window.open(res.url, '_blank');
  };

  const columns: any = [
    {
      title: 'Nama Pelanggan',
      dataIndex: 'customerName',
      key: 'customerName',
      width: '25%'
      // ...getColumnSearchProps('customerName', searchNameInput, updateFilter)
    },
    {
      title: 'Blok',
      dataIndex: 'block',
      key: 'block',
      width: '25%',
      ...getColumnSearchProps('blockNumber', searchBlockInput, updateFilter)
    },
    {
      title: 'Total Biaya Belum Dibayar',
      dataIndex: 'totalUnpaidAmount',
      key: 'totalUnpaidAmount',
      width: '25%'
    },
    {
      title: 'Aksi',
      key: 'operation',
      render: (record: DataType) => (
        <Space size="middle">
          <Button type="link" onClick={() => handleCreatePayment(record)}>
            Buat Pembayaran
          </Button>
          <Button type="link" onClick={() => handleSendWA(record)}>
            Kirim Reminder WA
          </Button>
          <MarkPaidManual collectionId={Number(record.key)} />
        </Space>
      )
    }
  ];

  const data: any = useMemo(
    () =>
      collections.map((collection: CustomerWithCollection) => ({
        key: collection.collectionId,
        id: collection.id,
        customerName: collection.name,
        block: collection.blockNumber,
        totalUnpaidAmount: formatCurrency(collection.totalAmount),
        invoice: collection.Invoice
      })),
    [collections]
  );

  return (
    <div>
      <Table
        columns={columns}
        expandable={{ expandedRowRender }}
        dataSource={data}
        pagination={{
          position: ['bottomCenter'],
          responsive: true,
          pageSizeOptions: [10, 20],
          showSizeChanger: true,
          total,
          onChange: handlePageChange
        }}
      />
      {htmlContent && <IframeModal
        htmlContent={htmlContent}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />}
    </div>
  );
};
