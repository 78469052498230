import { useState, useEffect } from 'react';
import { Link, Navigate, useSearchParams } from 'react-router-dom';
import '../../styles/Status.scss';
import { getPublicPayment } from 'services/public';
import { PaymentData } from 'interfaces/data';
import { formatCurrency } from 'utils';
import { InvoiceCard } from 'components/Invoices/InvoiceCard';
import { Button, Spin } from 'antd';
import { HomeOutlined } from '@ant-design/icons';

const StatusPage = () => {
  const [searchParams] = useSearchParams();
  const paymentId = searchParams.get('paymentId');
  const token = searchParams.get('token');
  const [payment, setPayment] = useState<PaymentData>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    console.log(token);
    console.log(paymentId);
    let isMounted = true;

    const fetchPayment = async () => {
      if (!(token && Number(paymentId))) {
        return;
      }
      try {
        const response = await getPublicPayment({
          token,
          id: Number(paymentId)
        });
        const updatedPayment = response.payment;
        setPayment(updatedPayment);
        if (updatedPayment.status === 'PENDING' && isMounted) {
          setTimeout(fetchPayment, 15000); // Fetch payment again after 5 seconds
        }
      } catch (error) {
        console.log(error);
      }
    };

    setIsLoading(true);
    fetchPayment();
    setIsLoading(false);

    return () => {
      isMounted = false; // Cleanup to prevent setting state on an unmounted component
    };
  }, [token, paymentId]);

  if (!(token && paymentId)) {
    if (token) {
      return <Navigate to={`/customer?token=${token}`} />;
    }
    return <Navigate to="/notfound" />;
  }

  return (
    <>
      {!isLoading && payment && (
        <div className="flex flex-col items-center w-screen pt-20 pb-10">
          {payment.status === 'PAID' && (
            <div className="successBackground">
              <p>Pembayaran Berhasil</p>
              <p className="paymentAmount">{formatCurrency(payment.amount)}</p>
            </div>
          )}
          {payment.status === 'PENDING' && (
            <div className="pendingBackground">
              <Spin />
              <p>Pembayaran Sedang Diproses</p>
              <p className="paymentAmount">{formatCurrency(payment.amount)}</p>
            </div>
          )}
          {payment.status === 'EXPIRED' ||
            (payment.status === 'CANCELLED' && (
              <div className="failedBackground">
                <p>Pembayaran Gagal</p>
                <p className="paymentAmount">
                  {formatCurrency(payment.amount)}
                </p>
              </div>
            ))}
          <div className="statusDetail">
            {payment.invoices.map((invoice) => {
              return (
                <InvoiceCard
                  invoice={invoice}
                  totalUsage={invoice.totalUsage}
                  amount={invoice.amount}
                  token={token}
                  key={invoice.id}
                />
              );
            })}
          </div>
          <Link className="mt-4 mb-2" to={`/customer?token=${token}`}>
            <Button type="primary" icon={<HomeOutlined />} className="flex items-center">
              Kembali ke halaman utama
            </Button>
          </Link>
        </div>
      )}
    </>
  );
};

export default StatusPage;
