import { Badge } from 'antd';
import { ReactNode } from 'react';
import {
  CustomerStatus,
  InvoiceStatus,
  PaymentMethod,
  StatusText
} from 'utils/constants';

const InvoiceStatusColor: Record<
  string,
  'warning' | 'error' | 'success' | 'processing' | 'default'
> = {
  AWAITING_PAYMENT: 'warning',
  PAID: 'success',
  CLOSED: 'default',
  REFUNDED: 'error'
};

const CustomerStatusColor: Record<string, 'success' | 'error' | 'warning'> = {
  ACTIVE: 'success',
  AWAITING_SUSPENSION: 'warning',
  INACTIVE: 'error'
};

export const CustomerBadge = ({ status }: { status: CustomerStatus }) => {
  return (
    <Badge status={CustomerStatusColor[status]} text={StatusText[status]} />
  );
};

export const InvoiceBadge = ({ status, method }: { status: InvoiceStatus, method?: PaymentMethod }) => {
  const text = status === InvoiceStatus.PAID && method ? `${StatusText[status]} (${method})` : StatusText[status];
  return (
    <Badge status={InvoiceStatusColor[status]} text={text} />
  );
};

export const InvoiceRibbonBadge = ({
  status,
  children
}: {
  status: InvoiceStatus;
  children: ReactNode;
}) => {
  return (
    <Badge.Ribbon text={StatusText[status]} color={InvoiceStatusColor[status]}>
      {children}
    </Badge.Ribbon>
  );
};
