import moment from 'moment';
import { useState, useEffect } from 'react';
import { StatisticsCard } from './StatisticsCard';
import { useYear } from 'context/YearContext';
import { useHousing } from 'context/HousingContext';
import { getYearlyUsages } from 'services/usage';

export const VolumeCard = () => {
  const { year } = useYear();
  const { housing } = useHousing();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [totalUsage, setTotalUsage] = useState<number>(0);

  useEffect(() => {
    const fetchTotalUsage = async () => {
      const date = moment(new Date(year, 0, 1)).format('YYYY-MM-DD');
      try {
        const params = housing === 'ALL_HOUSING' ? { date } : { date, housing };
        const response = await getYearlyUsages(params);
        const total = response.statistics.reduce(
          (sum, item) => sum + item.totalVolume,
          0
        );
        setTotalUsage(total);
      } catch (err) {
        console.log(err);
      }
    };
    setIsLoading(true);
    fetchTotalUsage();
    setIsLoading(false);
  }, [year, housing]);

  return (
    <StatisticsCard title="Total Volume" year={year} isLoading={isLoading}>
      <h3>{totalUsage}</h3>
    </StatisticsCard>
  );
};
