import { Button, message, Popconfirm } from 'antd';
import { ErrorResponse } from 'interfaces/api';
import axios, { AxiosError } from 'axios';
import { addManualPayment } from 'services/payment';

export const MarkPaidManual = ({
  collectionId
}: {
  collectionId: number;
}) => {
  const handleCreateManualPayment = async (collectionId) => {
    try {
      const response = await addManualPayment({ collectionId });
      if (response.message === 'OK') {
        message.success('Pembayaran manual berhasil ditambahkan');
        setTimeout(() => location.reload(), 1000);
      } else {
        message.error('Pembayaran manual gagal ditambahkan');
      }
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        console.log(err);
        const error = err as AxiosError<ErrorResponse>;
        alert(error.response?.data.message);
      }
    }
  };

  return (
    <Popconfirm
      title="Pembayaran Manual"
      description={<p>
        Apakah anda yakin untuk menandai transaksi ini dibayar manual?<br></br>
        <b>Proses ini tidak dapat dikembalikan</b>
      </p>}
      onConfirm={() => handleCreateManualPayment(collectionId)}
      okText="Lanjut"
      cancelText="Batal"
    >
      <Button type="dashed">Bayar Manual</Button>
    </Popconfirm>
  );
};
