import { InvoiceRibbonBadge } from 'components/ui/Badge';
import { InvoiceData, PaymentInvoiceData } from 'interfaces/data';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { formatCurrency } from 'utils';

export const InvoiceCard = ({
  invoice,
  totalUsage,
  amount,
  token
}: {
  invoice: InvoiceData | PaymentInvoiceData;
  totalUsage: number;
  amount: number;
  token: string;
}) => {
  return (
    <InvoiceRibbonBadge status={invoice.status}>
      <div className="cardContainer">
        <div className="flexRow">
          <p className="monthAndYear">
            {moment(invoice.date).format('MMM yyyy').toString()}
          </p>
        </div>
        <div className="flexRow">
          <div className="flexCol">
            <p className="fontGreyBold">Total Penggunaan : {totalUsage}</p>
            <p className="fontGrey">{`#${invoice.id}`}</p>
          </div>
          <p>{formatCurrency(amount)}</p>
        </div>
        <Link to={`/customer/invoiceDetail?id=${invoice.id}&token=${token}`}>
          <p className="text-right font-semibold text-gray-600 text-sm hover:text-blue-500">
            Lihat Detail <span>&rarr;</span>
          </p>
        </Link>
      </div>
    </InvoiceRibbonBadge>
  );
};
