import {
  AddPaymentResponse,
  GetCollectionResponse,
  GetInvoicesResponse,
  PublicParams,
  PublicPaymentParams,
  GetPaymentResponse
} from 'interfaces/api';
import { api } from 'utils/api';
import { HousingType } from 'utils/constants';

export const getPublicCollection = async (
  params: PublicParams
): Promise<GetCollectionResponse> => {
  const apiUrl = '/public/collection';
  const response = (await api.get(apiUrl, { params }))
    .data as GetCollectionResponse;
  return response;
};

export const getPublicInvoices = async (
  params: PublicParams
): Promise<GetInvoicesResponse> => {
  const apiUrl = '/public/invoices';
  const response = (await api.get(apiUrl, { params }))
    .data as GetInvoicesResponse;
  return response;
};

export const getPublicPayment = async (params: PublicPaymentParams) => {
  const apiUrl = '/public/payment';
  const response = (await api.get(apiUrl, { params }))
    .data as GetPaymentResponse;
  return response;
};

export const addPublicPayment = async (
  token: string,
  collectionId: number
): Promise<AddPaymentResponse> => {
  const apiUrl = `/public/payment?token=${token}`;
  const response = (await api.post(apiUrl, { collectionId }))
    .data as AddPaymentResponse;
  return response;
};

export const renderPublicInvoice = async (
  token: string,
  id: number
): Promise<string> => {
  const response = (
    await api.get(`public/invoices/render/${id}?token=${token}`)
  ).data as string;
  return response;
};

export const getPublicPrice = async (
  volume: number, housing: HousingType
): Promise<number> => {
  const response = (
    await api.get(`public/calculate-price?volume=${volume}&housing=${housing}`)
  ).data.price as number;
  return response;
};
