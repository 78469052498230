import { Layout, Select, Popover, Button } from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import IconImage from '../../assets/user.png';
import Logo from '../../assets/logo.png';
import '../../styles/Header.scss';
import { useToken } from 'context/TokenContext';
import { useHousing } from 'context/HousingContext';
import { HOUSINGS } from 'utils/constants';
import { ReactNode } from 'react';

const Header = ({ children }: { children: ReactNode }) => {
  const location = useLocation();
  const year = new Date().getFullYear();
  const { setHousing } = useHousing();
  const { setLogin } = useToken();
  const navigate = useNavigate();

  const options = [
    { value: 'ALL_HOUSING', label: 'All' },
    ...Object.entries(HOUSINGS).map(([type, housing]) => ({
      value: type,
      label: housing.name
    }))
  ];

  if (location.pathname === '/login' || location.pathname === '/register') {
    return null;
  }

  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
    setHousing(value);
  };

  const logout = () => {
    localStorage.removeItem('token');
    setLogin(false);
    navigate('/login');
  };

  const content = (
    <div>
      <Button type="link" className="logout-button" onClick={logout}>
        Logout
      </Button>
    </div>
  );

  return (
    <Layout>
      <Layout.Header>
        <img src={Logo} alt="logo" height="40px" width="40px" />
        <div className="navbar">
          <div className="left-navbar">
            <div className="menus">
              <Link className="menu" to="/admin">
                Dashboard
              </Link>
            </div>
            <div className="menus">
              <Link className="menu" to="/admin/customerList">
                Daftar Pelanggan
              </Link>
            </div>
            <div className="menus">
              <Link className="menu" to={`/admin/usage/${year}`}>
                Penggunaan
              </Link>
            </div>
            <div className="menus">
              <Link className="menu" to="/admin/invoiceList">
                Faktur
              </Link>
            </div>
            <div className="menus">
              <Link className="menu" to="/admin/debtList">
                Utang
              </Link>
            </div>
          </div>
          <div className="right-navbar">
            <div className="filter">
              <div className="filter-title">Perumahan:</div>
              <Select
                defaultValue="all"
                style={{ width: 220 }}
                onChange={handleChange}
                options={options}
              />
            </div>
          </div>
        </div>

        <div className="right">
          <Popover content={content}>
            <img
              style={{ width: '35px', height: '35px' }}
              src={IconImage}
              alt="logo"
            />
          </Popover>
        </div>
      </Layout.Header>
      <Layout.Content>{children}</Layout.Content>
    </Layout>
  );
};

export default Header;
