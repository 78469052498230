import { Breadcrumb, Space, Button, Form } from 'antd';
import { useParams, Link, Navigate } from 'react-router-dom';
import { useState } from 'react';
import moment from 'moment';
import '../../../styles/Global.scss';
import { UsageColumnData } from 'interfaces/data';
import { useModal } from 'context/ModalContext';
import { CustomModal } from 'components/ui/CustomModal';
import UsageForm from 'components/Usages/UsageForm';
import { MonthlyUsageList } from 'components/Usages/MonthlyUsageList';

const UsageDetailPage = () => {
  const { selectedMonth } = useParams();
  const { selectedYear } = useParams();
  const [modalContent, setModalContent] = useState<string>('Add Usage');
  const [currentUsage, setCurrentUsage] = useState<UsageColumnData>();
  const [form] = Form.useForm();

  const { setIsModalOpen } = useModal();

  const translatedMonth = new Date(
    `${selectedMonth || 'January'} 1, 2000`
  ).toLocaleString('id-ID', { month: 'long' });
  const month = moment(selectedMonth, 'MMMM');
  const monthNumber = month.month();

  const isValid = month.isValid() && !isNaN(Number(selectedYear));
  console.log(month);

  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  const handleEditUsage = (usage: UsageColumnData) => {
    form.resetFields();
    setCurrentUsage(usage);
    setModalContent('Ubah Penggunaan');
    setIsModalOpen(true);
  };

  const handleAddUsage = () => {
    form.resetFields();
    setModalContent('Tambah Penggunaan');
    setIsModalOpen(true);
  };

  if (!isValid) {
    return <Navigate to="/notfound" />;
  }
  return (
    <div>
      <div className="content">
        <div className="breadcrumb">
          <Breadcrumb style={{ margin: '16px 0' }}>
            <Breadcrumb.Item>Penggunaan</Breadcrumb.Item>
            <Breadcrumb.Item>
              {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
              <Link to={`/admin/usage/${selectedYear}`}>{selectedYear}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{translatedMonth}</Breadcrumb.Item>
          </Breadcrumb>
          <p>Penggunaan {translatedMonth}</p>
        </div>
        <div className="list">
          <Space
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginBottom: 10
            }}
          >
            <Button type="primary" onClick={handleAddUsage}>
              Tambah Penggunaan
            </Button>
          </Space>
          <MonthlyUsageList
            handleEditUsage={handleEditUsage}
            year={Number(selectedYear)}
            month={Number(monthNumber)}
          />
        </div>

        <CustomModal title={modalContent}>
          {modalContent === 'Ubah Penggunaan' && currentUsage ? (
            <UsageForm
              month={Number(monthNumber)}
              year={Number(selectedYear)}
              usage={currentUsage}
              handleCancel={handleCancel}
            />
          ) : (
            <UsageForm
              month={Number(monthNumber)}
              year={Number(selectedYear)}
              handleCancel={handleCancel}
            />
          )}
        </CustomModal>
      </div>
    </div>
  );
};

export default UsageDetailPage;
