import { useState, useEffect } from 'react';
import { StatisticsCard } from './StatisticsCard';
import { useYear } from 'context/YearContext';
import { useHousing } from 'context/HousingContext';
import { getCustomers } from 'services/customer';
import { params } from 'interfaces/api';

export const ActiveCustomerCard = () => {
  const { year } = useYear();
  const { housing } = useHousing();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [customerCount, setCustomerCount] = useState<number>(0);

  useEffect(() => {
    const fetchCustomerCount = async () => {
      try {
        setIsLoading(true);
        const params: params = {
          status: JSON.stringify(['ACTIVE', 'AWAITING_SUSPENSION'])
        };
        if (housing !== 'ALL_HOUSING') params.housing = housing;
        const response = await getCustomers(params);
        setCustomerCount(response.customersCount);
        setIsLoading(false);
      } catch (err) {
        console.log(err);
      }
    };
    fetchCustomerCount();
  }, [year, housing]);

  return (
    <StatisticsCard title="Pelanggan Aktif" isLoading={isLoading}>
      <h3>{customerCount}</h3>
    </StatisticsCard>
  );
};
