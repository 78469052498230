import axios from 'axios';
import { getToken } from 'utils/token-storage';
import { BACKEND_URL } from 'utils/constants';

export const api = axios.create({
  baseURL: BACKEND_URL
});

api.defaults.headers.common['Content-Type'] = 'application/json';

api.interceptors.request.use((config) => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});
