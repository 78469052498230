import { Button, Checkbox, Input, InputRef, Space } from 'antd';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { Ref, ReactNode } from 'react';

export const getColumnFilterProps = (
  dataIndex: string,
  options: any,
  onFilterChange: (key: string, value: string) => void
) => {
  // const handleReset = (clearFilters: () => void) => {
  //   clearFilters();
  //   onFilterChange(dataIndex, '');
  // };
  const handleFilterChange = (checkedValues: CheckboxValueType[]) => {
    onFilterChange(
      dataIndex,
      checkedValues.length > 0 ? JSON.stringify(checkedValues) : ''
    );
  };
  return {
    filterDropdown: ({
      confirm
      // clearFilters
    }: {
      setSelectedKeys: (keys: string[]) => void;
      selectedKeys: string[];
      confirm: (options?: { closeDropdown: boolean }) => void;
      // clearFilters: () => void;
    }): ReactNode => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Checkbox.Group
          options={options}
          defaultValue={[]}
          onChange={handleFilterChange}
          className="flex-col"
        />
        <Space>
          {/* <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
            type="primary"
          >
            Reset
          </Button> */}
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: true });
            }}
          >
            Tutup
          </Button>
        </Space>
      </div>
    )
  };
};

export const getColumnSearchProps = (
  dataIndex: string,
  searchInput: Ref<InputRef>,
  onInputChange: (key: string, value: string) => void
) => {
  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    onInputChange(dataIndex, '');
  };
  return {
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }: {
      setSelectedKeys: (keys: string[]) => void;
      selectedKeys: string[];
      confirm: (options?: { closeDropdown: boolean }) => void;
      clearFilters: () => void;
    }): ReactNode => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Space className="flex-col">
          <Input
            ref={searchInput}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0] || ''}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              onInputChange(dataIndex, e.target.value);
            }}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              onClick={() => clearFilters && handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
              type="primary"
            >
              Reset
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                confirm({ closeDropdown: true });
              }}
            >
              Tutup
            </Button>
          </Space>
        </Space>
      </div>
    )
  };
};
