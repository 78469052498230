import React, {
  ReactElement,
  createContext,
  useContext,
  useEffect,
  useState
} from 'react';

interface HousingContextValue {
  housing: string;
  setHousing: React.Dispatch<React.SetStateAction<string>>;
}

export const HousingContext = createContext<HousingContextValue>({
  housing: '',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setHousing: () => {}
});

export const HousingProvider = ({ children }: { children: ReactElement }) => {
  const [housing, setHousing] = useState<string>('ALL_HOUSING');

  useEffect(() => {
    setHousing('ALL_HOUSING');
  }, []);

  return (
    <HousingContext.Provider value={{ housing, setHousing }}>
      {children}
    </HousingContext.Provider>
  );
};

export const useHousing = () => useContext(HousingContext);
