import { Form, Space, Button } from 'antd';
import { CustomerList } from 'components/Customers/CustomerList';
import { useModal } from 'context/ModalContext';
import { useState } from 'react';
import { CustomModal } from 'components/ui/CustomModal';
import { CustomerForm } from 'components/Customers/CustomerForm';
import { CustomerData } from 'interfaces/data';
import { PageTitle } from 'components/ui/PageTitle';

const CustomerListPage = () => {
  const [modalContent, setModalContent] = useState('Add Customer');
  const [currentCust, setCurrentCust] = useState<CustomerData>();
  const { setIsModalOpen } = useModal();
  const [form] = Form.useForm();

  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  const handleEditCustomer = (customer: CustomerData) => {
    form.resetFields();
    setModalContent('Ubah Pelanggan');
    setCurrentCust(customer);
    setIsModalOpen(true);
  };

  const handleAddCustomer = () => {
    form.resetFields();
    setModalContent('Tambah Pelanggan');
    setIsModalOpen(true);
  };

  return (
    <div className="content">
      <PageTitle title="Daftar Pelanggan"/>
      <div className="list">
        <Space
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: 10
          }}
        >
          <Button type="primary" onClick={handleAddCustomer}>
            Tambah Pelanggan
          </Button>
        </Space>
        <CustomerList handleEditCustomer={handleEditCustomer} />
      </div>
      <CustomModal title={modalContent}>
        {modalContent === 'Ubah Pelanggan' && currentCust ? (
          <CustomerForm
            customer={currentCust}
            handleCancel={handleCancel}
          />
        ) : (
          <CustomerForm handleCancel={handleCancel} />
        )}
      </CustomModal>
    </div>
  );
};

export default CustomerListPage;
