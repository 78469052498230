import { useState, useEffect } from 'react';
import { StatisticsCard } from './StatisticsCard';
import { useYear } from 'context/YearContext';
import { useHousing } from 'context/HousingContext';
import { getInvoiceStatistics } from 'services/invoices';
import { params } from 'interfaces/api';
import { InvoiceStats } from 'interfaces/data';
import { formatCurrency } from 'utils';

export const InvoiceCard = () => {
  const { year } = useYear();
  const { housing } = useHousing();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [invoiceStats, setInvoiceStats] = useState<InvoiceStats>({
    totalAmount: 0,
    totalAmountPaid: 0,
    totalAmountUnpaid: 0
  });

  useEffect(() => {
    const fetchInvoiceStats = async () => {
      try {
        setIsLoading(true);
        const params: params = { year };
        if (housing !== 'ALL_HOUSING') params.housing = housing;
        const response = await getInvoiceStatistics(params);
        setInvoiceStats({ ...response });
        setIsLoading(false);
      } catch (err) {
        console.log(err);
      }
    };
    fetchInvoiceStats();
  }, [year, housing]);

  return (
    <StatisticsCard title="Total Pemasukan" year={year} isLoading={isLoading}>
      <div className="income-item">
        <h4>Total</h4>
        <h5>{formatCurrency(invoiceStats.totalAmount)}</h5>
      </div>
      <div className="income-item">
        <h4>Terbayar</h4>
        <h5>{formatCurrency(invoiceStats.totalAmountPaid)}</h5>
      </div>
      <div className="income-item">
        <h4>Belum Dibayar</h4>
        <h5>{formatCurrency(invoiceStats.totalAmountUnpaid)}</h5>
      </div>
    </StatisticsCard>
  );
};
