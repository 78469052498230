import { Skeleton } from 'antd';
import { useHousing } from 'context/HousingContext';
import { params } from 'interfaces/api';
import { PaymentData } from 'interfaces/data';
import moment from 'moment';
import { useState, useEffect } from 'react';
import { getPayments } from 'services/payment';
import { formatCurrency } from 'utils';

export const RecentPayments = () => {
  const { housing } = useHousing();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [payments, setPayments] = useState<PaymentData[]>();

  useEffect(() => {
    const fetchCustomerCount = async () => {
      try {
        setIsLoading(true);
        const params: params = {
          sort: 'createdAt,desc',
          page: 0,
          take: 10
        };
        if (housing !== 'ALL_HOUSING') params.housing = housing;
        const response = await getPayments(params);
        setPayments(response.payments);
        setIsLoading(false);
      } catch (err) {
        console.log(err);
      }
    };
    fetchCustomerCount();
  }, [housing]);

  return (
    <div className="row">
      <div className="recent-payment">
        <h2>Pembayaran</h2>
        <div className="payment-list">
          {isLoading ? (
            <Skeleton active paragraph={{ rows: 3 }} />
          ) : (
            payments?.map((payment) => {
              return (
                <div className="payment" key={payment.id}>
                  <div className="payment-item">
                    <h4>{payment.customer.name}</h4>
                    <h5>{moment(payment.date).format('DD/MM/YYYY')}</h5>
                  </div>
                  <div className="payment-item">
                    <h5>{formatCurrency(payment.amount)}</h5>{' '}
                    <h5>
                      {payment.status === 'PAID' ? (
                        `Paid - ${payment.method}`
                      ) : (
                        `${payment.status.slice(0, 1)}${payment.status.slice(1).toLowerCase()}`
                      )}
                    </h5>{' '}
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
    </div>
  );
};
