import { DatePicker, Space, Table, Button, Skeleton, Tooltip } from 'antd';
import { useState, useEffect, useMemo, useRef } from 'react';
import moment from 'moment';
import type { InputRef } from 'antd';
import { useHousing } from 'context/HousingContext';
import {
  getColumnFilterProps,
  getColumnSearchProps
} from 'components/ui/Table';
import { useFilters } from 'hooks/useFilters';
import { params } from 'interfaces/api';
import { usePaging } from 'hooks/usePaging';
import { getInvoiceStatistics, getInvoices } from 'services/invoices';
import { InvoiceData, InvoiceStats } from 'interfaces/data';
import { formatCurrency } from 'utils';
import { InvoiceBadge } from 'components/ui/Badge';
import { IS_DEBUG_MODE, InvoiceStatus } from 'utils/constants';
import { Card } from 'components/ui/Card';
import { getInvoicePDF } from '../../services/invoices';
import { IframeModal } from 'components/ui/IframeModal';
import 'styles/Invoice.scss';

const { RangePicker } = DatePicker;
export const InvoiceList = () => {
  const [invoices, setInvoices] = useState<InvoiceData[]>([]);
  const [total, setTotal] = useState(0);
  const [date, setDate] = useState<string[]>([]);
  const [sort, setSort] = useState('date,desc');
  const [statistics, setStatistics] = useState<InvoiceStats>();
  const { filter, updateFilter } = useFilters();
  const { housing } = useHousing();
  const { page, pageSize, handlePageChange } = usePaging();
  const [htmlContent, setHtmlContent] = useState<string>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const searchNameInput = useRef<InputRef>(null);
  const searchBlockInput = useRef<InputRef>(null);

  useEffect(() => {
    const fetchInvoices = async () => {
      try {
        const params: params = {
          ...filter,
          sort,
          page,
          take: pageSize,
          ...(housing !== 'ALL_HOUSING' && { housing }),
          ...(date && { startDate: date[0], endDate: date[1] })
        };

        const response = await getInvoices(params);
        setInvoices(response.invoices);
        setTotal(response.invoicesCount);
        const statisticParams: params = {
          ...filter,
          ...(housing !== 'ALL_HOUSING' && { housing }),
          ...(date && { startDate: date[0], endDate: date[1] }),
          status: undefined
        };
        const statisticsResponse = await getInvoiceStatistics(statisticParams);
        setStatistics(statisticsResponse);
      } catch (err: unknown) {
        console.log(err);
      }
    };

    fetchInvoices();
  }, [sort, date, filter, housing, page, pageSize]);

  const data = useMemo(
    () =>
      invoices.map((invoice: InvoiceData) => ({
        key: invoice.id,
        customerName: invoice.Usage.Customer.name,
        blockNumber: invoice.Usage.Customer.blockNumber,
        totalUsage: invoice.Usage.currentVolume,
        totalAmount: formatCurrency(invoice.totalAmount),
        status: invoice.status,
        paymentMethod: invoice.paymentMethod,
        date: invoice.date
      })),
    [invoices]
  );

  const statusOptions = [
    { label: 'Terbayar', value: 'PAID' },
    { label: 'Ditutup', value: 'CLOSED' },
    { label: 'Menunggu Pembayaran', value: 'AWAITING_PAYMENT' },
    { label: 'Dikembalikan', value: 'REFUNDED' }
  ];

  const viewPDF = async (id: number) => {
    const res = await getInvoicePDF(id);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    try {
      setHtmlContent(res);
      setIsModalOpen(true);
      console.log('aman sih');
    } catch (err) {
      console.log(err);
    }
  };

  const columns: any = [
    {
      title: 'Nama Pelanggan',
      dataIndex: 'customerName',
      key: 'customerName',
      ...getColumnSearchProps('customerName', searchNameInput, updateFilter)
    },
    {
      title: 'Blok',
      dataIndex: 'blockNumber',
      key: 'block',
      sorter: true,
      ...getColumnSearchProps('blockNumber', searchBlockInput, updateFilter)
    },
    {
      title: 'Total Penggunaan',
      dataIndex: 'totalUsage',
      key: 'totalUsage'
    },
    {
      title: 'Total Biaya',
      dataIndex: 'totalAmount',
      key: 'totalAmount',
      sorter: true
    },
    {
      title: 'Tanggal',
      dataIndex: 'date',
      key: 'date',
      render: (d: Date) => {
        return <span>{moment(d).format('MMM/yyyy')}</span>;
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      ...getColumnFilterProps('status', statusOptions, updateFilter),
      render: (status: InvoiceStatus, record: InvoiceData) => {
        console.log(record);
        return <InvoiceBadge status={status} method={record.paymentMethod} />;
      }
    },
    {
      title: 'Aksi',
      key: 'action',
      render: (record) => {
        return (
          <Space size="middle">
            {/* eslint-disable-next-line @typescript-eslint/no-unsafe-argument */}
            <Button type="link" onClick={() => viewPDF(record.key)}>
              Lihat PDF
            </Button>
            {IS_DEBUG_MODE && (
              <Tooltip title={`ID: ${record.key as string}`}>
                <span>Lihat ID</span>
              </Tooltip>
            )}
          </Space>
        );
      }
    }
  ];

  const handleDateChange = (value) => {
    if (value.length === 2) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      const start = value[0].startOf('month').format('YYYY-MM-DD');
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      const end = value[1].endOf('month').format('YYYY-MM-DD');
      setDate([start, end]);
      console.log(date);
    }
  };

  const handleTableChange = (...sorter) => {
    if (sorter[2].order === 'ascend') {
      setSort('totalAmount,asc');
    } else {
      setSort('totalAmount,desc');
    }
  };

  return (
    <div className="list">
      <Space className="dropdown">
        <RangePicker picker="month" onChange={handleDateChange} />
      </Space>
      <Table
        pagination={{
          position: ['bottomCenter'],
          responsive: true,
          pageSizeOptions: [10, 20],
          showSizeChanger: true,
          total,
          onChange: handlePageChange
        }}
        columns={columns}
        dataSource={data}
        onChange={handleTableChange}
      />
      <Card width={100}>
        <div className="top-item-value">
          {!statistics ? (
            <Skeleton active paragraph={{ rows: 3 }} />
          ) : (
            <div className="statistics">
              <div className="statistics__item">
                <p><b>Total Biaya</b></p>
                <p>{formatCurrency(statistics.totalAmount)}</p>
              </div>
              <div className="statistics__item">
                <p><b>Total Biaya Terbayar</b></p>
                <p>{formatCurrency(statistics.totalAmountPaid)}</p>
              </div>
              { statistics.totalPaidByMethod && (
                <div className="statistics__item">
                  <p>GOPAY: {formatCurrency(statistics.totalPaidByMethod.GOPAY)}</p>
                  <p>QRIS: {formatCurrency(statistics.totalPaidByMethod.QRIS)}</p>
                  <p>MANUAL: {formatCurrency(statistics.totalPaidByMethod.MANUAL)}</p>
                </div>
              ) }
              <div className="statistics__item">
                <p><b>Total Biaya Belum Dibayar</b></p>
                <p>{formatCurrency(statistics.totalAmountUnpaid)}</p>
              </div>
            </div>
          )}
        </div>
      </Card>
      {htmlContent && (
        <IframeModal
          htmlContent={htmlContent}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      )}
    </div>
  );
};
