import { PublicLayout } from 'components/layout/PublicLayout';
import { PaymentSteps } from '../components/Public/PaymentSteps';

const PaymentStepsPage = () => {
  return (
    <PublicLayout>
      <PaymentSteps />
    </PublicLayout>
  );
};

export default PaymentStepsPage;
