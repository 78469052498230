import { Breadcrumb } from 'antd';
import { InvoiceList } from 'components/Invoices/InvoiceList';
import { ModalProvider } from 'context/ModalContext';

const InvoicePage = () => {
  return (
    <ModalProvider>
      <div className="content">
        <div className="breadcrumb">
          <Breadcrumb style={{ margin: '16px 0' }}>
            <Breadcrumb.Item>Faktur</Breadcrumb.Item>
            <Breadcrumb.Item>Daftar Faktur</Breadcrumb.Item>
          </Breadcrumb>
          <p>Daftar Faktur</p>
        </div>
        <InvoiceList />
      </div>
    </ModalProvider>
  );
};

export default InvoicePage;
