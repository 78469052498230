import { Layout } from 'antd';
import Logo from '../../assets/logo.png';
import { Link } from 'react-router-dom';
import { ReactNode } from 'react';

export const PublicLayout = ({ children }: { children: ReactNode }) => {
  return (
    <Layout>
      <Layout.Header>
        <img src={Logo} alt="logo" height="40px" width="40px" />
        <div className="navbar">
          <div className="left-navbar">
            <div className="menus">
              <Link className="menu" to="/">
                Dashboard
              </Link>
            </div>
            <div className="menus">
              <Link className="menu" to="/calculator">
                Harga
              </Link>
            </div>
            <div className="menus">
              <Link className="menu" to="/payment-steps">
                Cara Bayar
              </Link>
            </div>
          </div>
        </div>
      </Layout.Header>
      <Layout.Content className="min-h-screen p-20 bg-gradient-to-r from-blue-100 to-teal-100 items-center my-auto">
        <div className="px-8 bg-white backdrop-blur-3xl bg-opacity-25 rounded-2xl shadow-lg mx-auto mt-8 w-full">
          {children}
        </div>
      </Layout.Content>
    </Layout>
  );
};
