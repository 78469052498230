import { Table, InputRef, Spin, Space, Button } from 'antd';
import { CustomerBadge } from 'components/ui/Badge';
import {
  getColumnFilterProps,
  getColumnSearchProps
} from 'components/ui/Table';
import { useHousing } from 'context/HousingContext';
import { useFilters } from 'hooks/useFilters';
import { usePaging } from 'hooks/usePaging';
import { params } from 'interfaces/api';
import { CustomerData } from 'interfaces/data';
import { useEffect, useMemo, useRef, useState } from 'react';
import { getCustomers, getQR } from 'services/customer';
import { formatTitle } from 'utils';
import { CustomerStatus, HOUSINGS, HousingType } from 'utils/constants';
import { useModal } from 'context/ModalContext';
import { IframeModal } from 'components/ui/IframeModal';

export const CustomerList = ({
  handleEditCustomer
}: {
  handleEditCustomer: (customer: CustomerData) => void;
}) => {
  const [customers, setCustomers] = useState<CustomerData[]>([]);
  const [customerCount, setCustomerCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [blockSort, setBlockSort] = useState('createdAt,desc');
  const { page, pageSize, handlePageChange } = usePaging();
  const { housing } = useHousing();
  const { filter, updateFilter } = useFilters();
  const { isModalOpen } = useModal();
  const [htmlContent, setHtmlContent] = useState<string>();
  const [isIframeModalOpen, setIsIframeModalOpen] = useState<boolean>(false);
  const searchNameInput = useRef<InputRef>(null);
  const searchPhoneNumberInput = useRef<InputRef>(null);
  const searchBlockNumberInput = useRef<InputRef>(null);

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const params: params = {
          ...filter,
          page,
          take: pageSize,
          sort: blockSort
        };
        if (housing !== 'ALL_HOUSING') params.housing = housing;
        const response = await getCustomers(params);
        setCustomers(response.customers);
        setCustomerCount(response.customersCount);
      } catch (err) {
        console.log(err);
      }
    };
    setIsLoading(true);
    if (!isModalOpen) fetchCustomers();
    setIsLoading(false);
  }, [housing, filter, page, pageSize, blockSort, isModalOpen]);

  const data = useMemo(
    () =>
      customers?.map((customer: CustomerData) => ({
        key: customer.id,
        id: customer.id,
        name: customer.name,
        blockNumber: customer.blockNumber,
        phoneNumber: customer.phoneNumber,
        status: customer.status,
        housing: customer.housing
      })) || [],
    [customers]
  );

  const handleTableChange = (...sorter: any) => {
    if (sorter[2].order === 'ascend') {
      setBlockSort('blockNumber,asc');
    } else {
      setBlockSort('blockNumber,desc');
    }
  };

  const statusOptions = Object.values(CustomerStatus).map((status) => ({
    label: formatTitle(status),
    value: status
  }));

  const showQR = async (record: CustomerData) => {
    try {
      const res = await getQR(record.id);
      setHtmlContent(res);
      setIsIframeModalOpen(true);
    } catch (err) {
      console.log(err);
    }
  };

  const columns: any = [
    {
      title: 'Nama',
      dataIndex: 'name',
      ...getColumnSearchProps('name', searchNameInput, updateFilter),
      ellipsis: true
    },
    {
      title: 'Perumahan',
      dataIndex: 'housing',
      ellipsis: true,
      render: (housing: HousingType) => HOUSINGS[housing].name
    },
    {
      title: 'Blok',
      dataIndex: 'blockNumber',
      sorter: true,
      ...getColumnSearchProps(
        'blockNumber',
        searchBlockNumberInput,
        updateFilter
      )
    },
    {
      title: 'No. Telepon',
      dataIndex: 'phoneNumber',
      ...getColumnSearchProps(
        'phoneNumber',
        searchPhoneNumberInput,
        updateFilter
      )
    },
    {
      title: 'Status',
      dataIndex: 'status',
      ...getColumnFilterProps('status', statusOptions, updateFilter),
      render: (status: CustomerStatus) => <CustomerBadge status={status} />
    },
    {
      title: 'Aksi',
      key: 'action',
      render: (record: CustomerData) => (
        <Space size="middle">
          <Button type="link" onClick={() => handleEditCustomer(record)}>
            Ubah
          </Button>
          <Button type="link" onClick={() => showQR(record)}>
            QR Pelanggan
          </Button>
        </Space>
      )
    }
  ];

  return (
    <Spin spinning={isLoading}>
      <Table
        pagination={{
          position: ['bottomCenter'],
          responsive: true,
          pageSizeOptions: [10, 20],
          showSizeChanger: true,
          total: customerCount,
          onChange: handlePageChange
        }}
        columns={columns}
        dataSource={data}
        onChange={handleTableChange}
      />
      {htmlContent && (
        <IframeModal
          htmlContent={htmlContent}
          isModalOpen={isIframeModalOpen}
          setIsModalOpen={setIsIframeModalOpen}
        />
      )}
    </Spin>
  );
};
