import { useState } from 'react';

export const usePaging = () => {
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const handlePageChange = (page: number, pageSize: number) => {
    console.log(page, pageSize);
    setPage(page);
    setPageSize(pageSize);
    console.log('xx');
  };

  return { page, pageSize, handlePageChange };
};
