import { Table, InputRef, Spin, Space, Button } from 'antd';
import { CustomerBadge } from 'components/ui/Badge';
import {
  getColumnFilterProps,
  getColumnSearchProps
} from 'components/ui/Table';
import { useHousing } from 'context/HousingContext';
import { useModal } from 'context/ModalContext';
import { useFilters } from 'hooks/useFilters';
import { usePaging } from 'hooks/usePaging';
import { usageParams } from 'interfaces/api';
import { Usage, UsageColumnData } from 'interfaces/data';
import moment from 'moment';
import { useRef, useState, useEffect, useMemo } from 'react';
import { getUsages } from 'services/usage';
import { formatTitle } from 'utils';
import { CustomerStatus } from 'utils/constants';

export const MonthlyUsageList = ({
  handleEditUsage,
  month,
  year
}: {
  handleEditUsage: (usage: UsageColumnData) => void;
  month: number;
  year: number;
}) => {
  const nameSearchInput = useRef<InputRef>(null);
  const blockSearchInput = useRef<InputRef>(null);
  const { filter, updateFilter } = useFilters();
  const [sortTotalUsage, setSortTotalUsage] = useState<string>('');
  const { housing } = useHousing();
  const [usages, setUsages] = useState<Usage[]>();
  const { page, pageSize, handlePageChange } = usePaging();
  const [total, setTotal] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { isModalOpen } = useModal();

  const statusOptions = Object.values(CustomerStatus).map((status) => ({
    label: formatTitle(status),
    value: status
  }));

  useEffect(() => {
    const fetchUsages = async () => {
      const date = moment(new Date(Number(year), month, 1)).format(
        'YYYY-MM-DD'
      );
      console.log(date);
      const params: usageParams = { ...filter, date, page, take: pageSize };
      if (housing !== 'ALL_HOUSING') params.housing = housing;
      const response = await getUsages(params);
      console.log(response.usages);
      setUsages(response.usages);
      setTotal(response.usagesCount);
    };
    setIsLoading(true);
    if (!isModalOpen) fetchUsages();
    setIsLoading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filter,
    housing,
    sortTotalUsage,
    page,
    pageSize,
    month,
    year,
    isModalOpen
  ]);

  const data = useMemo(
    () =>
      usages?.map((usage: Usage) => ({
        key: usage.id,
        id: usage.id,
        date: usage.date,
        customerName: usage.Customer.name,
        customerId: usage.customerId,
        blockNumber: usage.Customer.blockNumber,
        housing: usage.Customer.housing,
        customerStatus: usage.Customer.status,
        totalUsage: usage.currentVolume,
        oldVolume: usage.oldVolume,
        newVolume: usage.newVolume
      })) || [],
    [usages]
  );

  const columns: any = [
    {
      title: 'Nama',
      dataIndex: 'customerName',
      key: 'customerName',
      ...getColumnSearchProps('customerName', nameSearchInput, updateFilter)
    },
    {
      title: 'Blok',
      dataIndex: 'blockNumber',
      key: 'blockNumber',
      ...getColumnSearchProps('blockNumber', blockSearchInput, updateFilter)
    },
    {
      title: 'Status',
      key: 'customerStatus',
      dataIndex: 'customerStatus',
      ...getColumnFilterProps('customerStatus', statusOptions, updateFilter),
      render: (status: CustomerStatus) => <CustomerBadge status={status} />,
      filters: [
        {
          text: 'Aktif',
          value: 'ACTIVE'
        },
        {
          text: 'Nonaktif',
          value: 'INACTIVE'
        },
        {
          text: 'Menunggu Penangguhan',
          value: 'AWAITING_SUSPENSION'
        }
      ]
    },
    {
      title: 'Total Penggunaan',
      dataIndex: 'totalUsage',
      key: 'totalUsage',
      sorter: true
    },
    {
      title: 'Aksi',
      key: 'action',
      render: (record: UsageColumnData) => {
        return (
          <Space size="middle">
            <Button type="link" onClick={() => handleEditUsage(record)}>
              Ubah
            </Button>
          </Space>
        );
      }
    }
  ];

  const handleTableChange = (...sorter) => {
    if (sorter[2].order === 'ascend') {
      setSortTotalUsage('currentVolume,asc');
    } else if (sorter[2].order === 'descend') {
      setSortTotalUsage('currentVolume,desc');
    }
  };

  return (
    <Spin spinning={isLoading}>
      <Table
        columns={columns}
        dataSource={data}
        pagination={{
          position: ['bottomCenter'],
          responsive: true,
          pageSizeOptions: [10, 20],
          showSizeChanger: true,
          total,
          onChange: handlePageChange
        }}
        onChange={handleTableChange}
      />
      ;
    </Spin>
  );
};
