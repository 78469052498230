import { useEffect, useState } from 'react';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { message, Pagination, PaginationProps } from 'antd';
import './LandingPage.scss';
import {
  addPublicPayment,
  getPublicCollection,
  getPublicInvoices
} from 'services/public';
import { Collection, InvoiceData } from 'interfaces/data';
import { InvoiceCard } from 'components/Invoices/InvoiceCard';
import axios, { AxiosError } from 'axios';
import { ErrorResponse } from 'interfaces/api';
import { formatCurrency } from 'utils';

export default function LandingPage() {
  const [invoices, setInvoices] = useState<InvoiceData[]>([]);
  const [collection, setCollection] = useState<Collection>();
  const [searchParams] = useSearchParams();
  const customerToken = searchParams.get('token');
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);

  const getInvoices = async (page: number) => {
    if (!customerToken) {
      return;
    }

    setIsLoading(true);
    const response = await getPublicInvoices({
      token: customerToken,
      page,
      take: 10,
      sort: 'createdAt,desc'
    });
    setInvoices(response.invoices);
    setMaxPage(response.pagesCount);
  };

  useEffect(() => {
    if (!customerToken) {
      return;
    }
    const getCollection = async () => {
      const response = await getPublicCollection({ token: customerToken });
      setCollection(response.collection);
    };

    setIsLoading(true);
    getInvoices(page);
    getCollection();
    setIsLoading(false);
  }, [customerToken]);

  const onPageChange: PaginationProps['onChange'] = (page: number) => {
    setPage(page);
    setIsLoading(true);
    getInvoices(page);
    setIsLoading(false);
  };

  const handlePayment = (paymentId: number) => {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    navigate(`/customer/payment?paymentId=${paymentId}&token=${customerToken}`);
  };

  const handleClick = async () => {
    if (!(collection && customerToken)) {
      return;
    }
    try {
      const response = await addPublicPayment(customerToken, collection.id);
      const token = response.payment.pgToken;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      window.snap.pay(token, {
        onSuccess: function () {
          handlePayment(response.payment.id);
        },
        onPending: function () {
          handlePayment(response.payment.id);
        },
        onError: function () {
          handlePayment(response.payment.id);
        },
        onClose: function () {
          message.info('Pembayaran ditutup');
        }
      });
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        const error = err as AxiosError<ErrorResponse>;
        alert(error.response?.data.message);
      }
    }
  };

  if (!customerToken) {
    return <Navigate to="/notfound" />;
  }

  return (
    <>
      {!isLoading && (
        <div className="flex flex-col items-center h-screen w-screen pt-20 ">
          <div className="w-full">
            <div className="backgroundImage">
              <div>
                <h5 style={{ color: 'white' }}>Total Tagihan: </h5>
                <p className="price">{`${
                  collection
                    ? formatCurrency(collection.totalAmount)
                    : formatCurrency(0)
                }`}</p>
              </div>
              {collection && (
                <button className="payButton" onClick={handleClick}>
                  Bayar
                </button>
              )}
            </div>
            {invoices.map((invoice) => (
              <InvoiceCard
                key={invoice.id}
                invoice={invoice}
                token={customerToken}
                totalUsage={invoice.Usage.currentVolume}
                amount={invoice.totalAmount}
              />
            ))}
            <Pagination current={page} onChange={onPageChange} align="center" pageSize={10} total={maxPage * 10} />
          </div>
        </div>
      )}
    </>
  );
}
