import PropTypes from 'prop-types';

export const PageContent = ({ children }) => {
  return (
    <div className="list">
      {children}
    </div>
  );
};

PageContent.propTypes = {
  children: PropTypes.node
};
