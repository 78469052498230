import { PageTitle } from 'components/ui/PageTitle';
import { PageContent } from 'components/ui/PageContent';
import { YearProvider } from 'context/YearContext';
import { DashboardContent } from 'components/Dashboard/DashboardContent';

export default function Dashboard() {
  return (
    <YearProvider>
      <div className="content">
        <PageTitle title="Dashboard" />
        <PageContent>
          <DashboardContent />
        </PageContent>
      </div>
    </YearProvider>
  );
}
