import { DatePicker, Space } from 'antd';
import { useYear } from 'context/YearContext';
import dayjs from 'dayjs';

export const YearFilterBox = ({
  defaultYear,
  handleChangeYear
}: {
  defaultYear?: number | string;
  handleChangeYear?: (value: dayjs.Dayjs | null) => void;
}) => {
  const { setYear } = useYear();
  return (
    <Space className="dropdown">
      <DatePicker
        picker="year"
        onChange={(value) =>
          handleChangeYear ? handleChangeYear(value) : (value && setYear(value.year()))
        }
        defaultValue={
          defaultYear ? dayjs(`${defaultYear}-01-01`, 'YYYY') : dayjs()
        }
      />
    </Space>
  );
};
