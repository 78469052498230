import { Skeleton } from 'antd';
import { Card } from 'components/ui/Card';
import PropTypes from 'prop-types';

export const StatisticsCard = ({ title, year, children, isLoading }) => {
  return (
    <Card width={32}>
      <div className="top-item-title">
        <h3>{title}</h3>
        <h5>{year}</h5>
      </div>
      <div className="top-item-value">
        {isLoading ? <Skeleton active paragraph={{ rows: 3 }} /> : children}
      </div>
    </Card>
  );
};

StatisticsCard.propTypes = {
  title: PropTypes.string,
  year: PropTypes.number,
  children: PropTypes.node,
  isLoading: PropTypes.bool
};
