import { Tag } from 'antd';
import { formatCurrency, formatTitle } from 'utils';
import { InvoiceStatus } from 'utils/constants';

const InvoiceStatusColor = {
  [InvoiceStatus.PAID]: 'success',
  [InvoiceStatus.AWAITING_PAYMENT]: 'default',
  [InvoiceStatus.CLOSED]: 'error',
  [InvoiceStatus.REFUNDED]: 'warning'
};

export const InvoiceStatusTag = ({
  status,
  totalAmount
}: {
  status: InvoiceStatus;
  totalAmount: number;
}) => {
  if (!status) {
    return (<div/>);
  }
  return (
    <Tag color={InvoiceStatusColor[status]}>
      {status === InvoiceStatus.AWAITING_PAYMENT
        ? formatCurrency(totalAmount)
        : formatTitle(status)}
    </Tag>
  );
};
