import { AuthForm } from 'components/Auth/AuthForm';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useToken } from 'context/TokenContext';
const LoginPage: React.FC = () => {
  const { login } = useToken();
  const navigate = useNavigate();
  useEffect(() => {
    if (login) {
      navigate('/admin');
    }
  }, [login]);

  return <AuthForm title="login" />;
};

export default LoginPage;
