import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Select,
  Space,
  Spin,
  message
} from 'antd';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { CustomerData, UsageColumnData } from 'interfaces/data';
import { addUsage, getUsageByCustomerId, updateUsage } from 'services/usage';
import { AddUsageRequest, ErrorResponse, UpdateUsageRequest, usageParams } from 'interfaces/api';
import { formatTitle } from 'utils';
import dayjs from 'dayjs';
import { getCustomersByUsage } from 'services/customer';
import axios, { AxiosError } from 'axios';
import { useModal } from 'context/ModalContext';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useHousing } from 'context/HousingContext';

const UsageForm = ({
  month,
  year,
  usage,
  handleCancel
}: {
  month: number;
  year: number;
  usage?: UsageColumnData;
  handleCancel: () => void;
}) => {
  const { housing } = useHousing();
  const [blockSearch, setBlockSearch] = useState<string>('');
  const [initialAvailableCustomers, setInitialAvailableCustomers] = useState<CustomerData[]>(
    []
  );
  const [availableCustomers, setAvailableCustomers] = useState<CustomerData[]>(
    []
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOldVolume, setIsOldVolume] = useState<boolean>(false);
  const [fixedOldVolume, setFixedOldVolume] = useState<number>(0);
  const [resetOldVolume, setResetOldVolume] = useState<boolean>(false);
  const [form] = Form.useForm();
  const date = moment(new Date(Number(year), month, 1)).format('YYYY-MM-DD');
  const { setIsModalOpen } = useModal();

  useEffect(() => {
    const getAvailableCustomers = async () => {
      try {
        form.resetFields();
        const params: usageParams = {
          hasUsage: false,
          date,
          status: 'ACTIVE',
          sort: 'createdAt,desc',
          take: 10
        };
        if (housing !== 'ALL_HOUSING') params.housing = housing;
        const response = await getCustomersByUsage(params);
        const customers = response.customers;
        setAvailableCustomers(customers);
        setInitialAvailableCustomers(customers);
      } catch (err) {
        console.log(err);
      }
    };

    setIsLoading(true);
    if (usage) {
      form.setFieldsValue({
        customerId: usage.customerName,
        blockNumber: usage.blockNumber,
        housing: formatTitle(usage.housing),
        oldVolume: usage.oldVolume,
        newVolume: usage.newVolume
      });
      setIsOldVolume(true);
      setFixedOldVolume(usage.oldVolume);
    } else {
      getAvailableCustomers();
      setIsOldVolume(false);
    }
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [housing, usage, year, month]);

  const handleCustomerSearch = async (value: string) => {
    setBlockSearch(value);
    if (value === '') {
      setAvailableCustomers(initialAvailableCustomers);
      return;
    }
    try {
      const params: usageParams = {
        hasUsage: false,
        blockNumber: value,
        date,
        status: 'ACTIVE',
        sort: 'createdAt,desc',
        take: 10
      };
      if (housing !== 'ALL_HOUSING') params.housing = housing;
      const response = await getCustomersByUsage(params);
      const customers = response.customers;
      setAvailableCustomers(customers);
    } catch (err) {
      console.log(err);
    }
  };

  const handleCustomerChange = async (value: any) => {
    const selectedCustomer = availableCustomers.find(
      (customer) => customer.id === value
    );

    const getPreviousMonth = (month: number, year: number) => {
      if (month === 0) {
        return { month: 11, year: year - 1 };
      } else {
        return { month: month - 1, year };
      }
    };
    const prevMonth = getPreviousMonth(month, year);
    const prevDate = moment(
      new Date(Number(prevMonth.year), prevMonth.month, 1)
    ).format('YYYY-MM-DD');

    try {
      const params: usageParams = {
        customerId: form.getFieldValue('customerId'),
        date: prevDate
      };
      if (params.customerId) {
        const response = await getUsageByCustomerId(params);
        console.log(response);
        form.setFieldValue('oldVolume', response.usage.newVolume);
        setFixedOldVolume(response.usage.newVolume);
        setResetOldVolume(false);
        setIsOldVolume(true);
      }
    } catch (err) {
      form.setFieldValue('oldVolume', '');
      setIsOldVolume(false);
    }

    form.setFieldsValue({
      blockNumber: selectedCustomer?.blockNumber,
      housing: formatTitle(selectedCustomer?.housing || '')
    });
  };

  const handleResetOldVolume = (e: CheckboxChangeEvent) => {
    setResetOldVolume(e.target.checked);
    form.setFieldValue('oldVolume', fixedOldVolume);
  };

  const handleSubmit = async (values: any) => {
    try {
      if (usage) {
        console.log(usage);
        const usageData: UpdateUsageRequest = {
          id: usage.id,
          resetOldVolume,
          newVolume: Number(values.newVolume),
          oldVolume: Number(values.oldVolume),
          date: new Date(Number(year), month, 1)
        };
        console.log(usageData);
        await updateUsage(usageData);
        message.success('Usage edited successfully!');
        setFixedOldVolume(Number(values.oldVolume));
      } else {
        const usageData: AddUsageRequest = {
          customerId: values.customerId,
          date,
          resetOldVolume,
          oldVolume: Number(values.oldVolume),
          newVolume: Number(values.newVolume)
        };
        console.log(usageData);
        await addUsage(usageData);
        message.success('Usage added successfully');
        setFixedOldVolume(Number(values.oldVolume));
      }
      form.resetFields();
      setIsOldVolume(false);
      setResetOldVolume(false);
      setIsModalOpen(false);
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        const error = err as AxiosError<ErrorResponse>;
        message.error(error.response?.data.message);
      } else {
        console.log(err);
      }
    }
  };

  return (
    <Spin spinning={isLoading}>
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        form={form}
        autoComplete="off"
        onFinish={handleSubmit}
      >
        <Form.Item
          className="form-name"
          label="Pelanggan"
          name="customerId"
          rules={[{ required: true, message: 'Pelanggan harus dipilih!' }]}
        >
          <Select
            showSearch
            searchValue={blockSearch}
            onSearch={handleCustomerSearch}
            filterOption={false}
            style={{ width: 200 }}
            placeholder="Pilih pelanggan"
            onSelect={handleCustomerChange}
            disabled={!!usage}
            options={availableCustomers.map((customer) => {
              return {
                value: customer.id,
                label: customer.name + ' [' + customer.blockNumber + ']'
              };
            })}
          ></Select>
        </Form.Item>
        <Form.Item className="form-block" name="housing" label="Perumahan">
          <Input disabled />
        </Form.Item>
        <Form.Item className="form-block" name="blockNumber" label="Blok">
          <Input disabled />
        </Form.Item>
        <Form.Item className="form-block" name="date" label="Bulan">
          <DatePicker
            defaultValue={dayjs(`1/${month + 1}/${year}`, 'D/M/YYYY')}
            format={'MM/YYYY'}
            disabled
          />
        </Form.Item>
        <Form.Item
          className="form-block"
          name="oldVolume"
          label="Volume Lama"
          rules={[
            { required: true, message: 'Volume lama tidak boleh kosong' }
          ]}
        >
          <InputNumber min={0} disabled={isOldVolume && !resetOldVolume} />
        </Form.Item>
        <Form.Item
          label="Volume Baru"
          name="newVolume"
          rules={[
            { required: true, message: 'Volume baru tidak boleh kosong' }
          ]}
        >
          <InputNumber min={0} />
        </Form.Item>
        {isOldVolume &&
          <Form.Item label="Pernyataan">
            <Checkbox
              onChange={handleResetOldVolume}
              checked={resetOldVolume}
            >
              Saya ingin melakukan perubahan terhadap volume lama
            </Checkbox>
          </Form.Item>
        }
        <Space
          style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 10 }}
        >
          <Button
            key="back"
            onClick={() => {
              handleCancel();
            }}
          >
            Batal
          </Button>
          <Button type="primary" htmlType="submit">
            Simpan
          </Button>
        </Space>
      </Form>
    </Spin>
  );
};

export default UsageForm;
