// import LoggedIn from "renderer/components/LoggedIn";
import { Space, Breadcrumb, Table } from 'antd';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import moment from 'moment';
import dayjs from 'dayjs';
import { useHousing } from 'context/HousingContext';
import { YearFilterBox } from 'components/ui/FilterBox';
import { getYearlyUsages } from 'services/usage';
import { MonthlyUsage } from 'interfaces/data';
import { usageParams } from 'interfaces/api';

interface Usage {
  month: string;
  totalVolume: number;
}
const UsagePage = () => {
  const { thisYear } = useParams();
  const defaultYear = new Date().getFullYear();
  const [usages, setUsages] = useState<Usage[]>([]);
  const navigate = useNavigate();

  const { housing } = useHousing();

  useEffect(() => {
    const fetchUsages = async () => {
      try {
        const date = moment(new Date(Number(thisYear), 0, 1)).format(
          'YYYY-MM-DD'
        );
        console.log(date);
        const params: usageParams = { date };
        if (housing !== 'ALL_HOUSING') params.housing = housing;
        const response = await getYearlyUsages(params);
        setUsages(response.statistics);
      } catch (err: unknown) {
        console.log(err);
      }
    };
    const isValidYear = !isNaN(Number(thisYear));

    if (isValidYear) {
      fetchUsages();
    } else {
      navigate('/notfound');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [housing, thisYear]);

  const columns = [
    {
      title: 'Bulan',
      key: 'month',
      dataIndex: 'month'
    },
    {
      title: 'Total Volume',
      dataIndex: 'totalVolume',
      key: 'totalVolume'
    },
    {
      title: 'Aksi',
      key: 'action',
      render: (record: MonthlyUsage) => (
        <Space size="middle">
          <Link to={`/admin/usage/${thisYear || defaultYear}/${record.month}`}>
            Lihat detail
          </Link>
        </Space>
      )
    }
  ];

  const handleChangeYear = (value: dayjs.Dayjs | null) => {
    if (!value) {
      return;
    }
    navigate(`/admin/usage/${value.year()}`);
  };
  return (
    <div className="content">
      <div className="breadcrumb">
        <Breadcrumb style={{ margin: '16px 0' }}>
          <Breadcrumb.Item>Penggunaan</Breadcrumb.Item>
          <Breadcrumb.Item>{thisYear}</Breadcrumb.Item>
        </Breadcrumb>
        <p>Penggunaan</p>
      </div>
      <div className="list">
        <YearFilterBox
          defaultYear={thisYear}
          handleChangeYear={handleChangeYear}
        />
        <Table pagination={false} columns={columns} dataSource={usages} />
      </div>
    </div>
  );
};

export default UsagePage;
