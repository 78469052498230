import { AuthForm } from 'components/Auth/AuthForm';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useToken } from 'context/TokenContext';
import { DISABLE_REGISTER } from 'utils/constants';

const RegisterPage: React.FC = () => {
  const { login } = useToken();
  const navigate = useNavigate();
  useEffect(() => {
    if (login) {
      navigate('/admin');
    }
    if (DISABLE_REGISTER) {
      navigate('/login');
    }
  }, [login]);

  return <AuthForm title="register" />;
};

export default RegisterPage;
