import { ReactNode } from 'react';
export const Card = ({
  children,
  width
}: {
  children: ReactNode;
  width: number;
}) => {
  const style = width ? { width: `${width}%` } : {};

  return (
    <div className="top-items" style={style}>
      {children}
    </div>
  );
};
