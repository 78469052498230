import { useToken } from 'context/TokenContext';
import { Header } from 'pages';
import React, { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

interface ProtectedRouteProps {
  fallback: string;
  children: ReactNode;
}
const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  fallback,
  children
}) => {
  const { login, loading } = useToken();

  if (!login && !loading) {
    return <Navigate replace to={fallback} />;
  }
  return <Header>{children}</Header>;
};

export default ProtectedRoute;
