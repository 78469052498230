import { useState } from 'react';

export const useFilters = () => {
  const [filter, setFilter] = useState({});
  const updateFilter = (key: string, value: string) => {
    if (value.length === 0) {
      const newFilter = { ...filter };
      delete newFilter[key];
      setFilter(newFilter);
    } else {
      setFilter((prevFilter) => ({
        ...prevFilter,
        [key]: value
      }));
    }
  };
  return { filter, updateFilter };
};
