import { Button, Divider, Form, FormInstance, InputNumber, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { getPublicPrice } from 'services/public';
import { formatCurrency } from 'utils';

export const CalculatorCard = () => {
  const [volume, setVolume] = useState<number>(0);
  const [price, setPrice] = useState<string>(formatCurrency(0));
  const formRef = React.useRef<FormInstance>(null);

  useEffect(() => {
    const calculatePrice = async () => {
      try {
        const newPrice = (await getPublicPrice(volume, 'PASIR_JATI_INDAH'));
        setPrice(formatCurrency(newPrice));
        console.log(newPrice);
      } catch (err) {
        console.log(err);
      }
    };
    calculatePrice();
    formRef.current?.setFieldValue('price', price);
    console.log(formRef.current);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [volume]);

  const onFinish = (values: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    setVolume(values.volume);
  };

  return (
    <div className="p-20">
      <p className="text-2xl font-bold mb-2">Harga</p>
      <p>
        Harga yang dibayarkan bergantung pada penggunaan air setiap bulannya.
        Volume air (m<sup>3</sup>) yang digunakan akan dihitung setiap akhir
        bulan.
      </p>
      <br />
      <p>Berikut adalah harga berdasarkan penggunaan air</p>
      <ol>
        <Divider />
        <li>
          1. Untuk pemakaian 10 m<sup>3</sup> pertama dikenakan biaya Rp.
          3.500,- per m<sup>3</sup> (Pemakaian 0-10 m<sup>3</sup>)
        </li>
        <Divider />
        <li>
          2. Untuk pemakaian 10 m<sup>3</sup> selanjutnya dikenakan biaya Rp.
          4.500,- per m<sup>3</sup> (Pemakaian 11-20 m<sup>3</sup>)
        </li>
        <Divider />
        <li>
          3. Untuk pemakaian 10 m<sup>3</sup> selanjutnya dikenakan biaya Rp.
          5.500,- per m<sup>3</sup> (Pemakaian 21-30 m<sup>3</sup>)
        </li>
        <Divider />
        <li>
          4. Untuk pemakaian diatas 30 m<sup>3</sup> dikenakan biaya Rp. 6.500,-
          per m<sup>3</sup>
        </li>
        <Divider />
      </ol>
      <p>
        Pembayaran rekening air paling lambat tanggal 20 setiap bulannya.
        Apabila lewat tanggal tersebut dikenakan denda sebesar Rp. 5.000,-.
        Selain itu, apabila dalam 3 bulan rekening tidak dibayar, maka akan
        dilakukan pemutusan. Penyambungan kembali akan dikenakan biaya dan harus
        tetap melunasi tunggakan sebelumnya.
      </p>
      <p className="text-2xl font-bold my-4">Hitung Harga</p>
      <Form
        name="basic"
        labelCol={{ span: 4 }}
        wrapperCol={{ offset: 1, span: 16 }}
        onFinish={onFinish}
        ref={ formRef }
        className="bg-white p-8 rounded-xl max-w-lg bg-opacity-70"
      >
        <Form.Item label="Volume" name="volume" rules={[{ required: true, message: 'Volume tidak boleh kosong!' }]}>
          <Space.Compact block>
            <InputNumber defaultValue={0} min={0}/>
          </Space.Compact>
        </Form.Item>
        <Form.Item
          label="Harga"
          name="price"
        >
          <b>{price}</b>
        </Form.Item>
        <Form.Item wrapperCol={{ span: 16 }}>
          <Button type="primary" htmlType="submit">
            Hitung
          </Button>
        </Form.Item>
        <p>Harga diatas merupakan harga penggunaan air. Harga total dapat berbeda karena adanya biaya lain seperti biaya beban dan lain lain. Detail biaya akan dirinci dalam tagihan setiap bulannya.</p>
      </Form>
    </div>
  );
};
