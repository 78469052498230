import { Spin } from 'antd';
import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { renderPublicInvoice } from 'services/public';

const InvoiceDetail = () => {
  const [searchParams] = useSearchParams();
  const [htmlContent, setHtmlContent] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const id = searchParams.get('id');
  const customerToken = searchParams.get('token');

  useEffect(() => {
    console.log(customerToken);
    console.log(id);
    if (!customerToken || !Number(id)) {
      return;
    }
    const getInvoice = async () => {
      try {
        const res = await renderPublicInvoice(customerToken, Number(id));
        setHtmlContent(res);
        console.log(res);
      } catch (err) {
        console.log(err);
      }
    };

    setIsLoading(true);
    getInvoice();
    setIsLoading(false);
  }, [customerToken, id]);

  return (
    <div
      style={{
        padding: '100px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Spin spinning={isLoading}>
        <iframe
          srcDoc={htmlContent}
          className="modal-iframe"
          style={{
            width: '650px',
            height: '80vh'
          }}
        />
      </Spin>
    </div>
  );
};

export default InvoiceDetail;
