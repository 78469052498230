import { AuthFormData } from 'interfaces/auth';

import { AuthResponse } from 'interfaces/api';
import { setToken } from 'utils/token-storage';
import { api } from 'utils/api';

export const login = async (payload: AuthFormData) => {
  const response = await api.post('/auth/login', payload);
  const data = response.data as AuthResponse;
  setToken(data.auth.access_token);
  return data;
};

export const register = async (payload: AuthFormData) => {
  const response = await api.post('/auth/register', payload);
  const data = response.data as AuthResponse;
  setToken(data.auth.access_token);
  return data;
};
