import { Button, Form, Input, Radio, Select, Space, message } from 'antd';
import { CustomerData } from 'interfaces/data';
import { useState, useEffect } from 'react';
import { HOUSINGS, HousingType } from 'utils/constants';
import { useModal } from 'context/ModalContext';
import { addCustomer, updateCustomer } from 'services/customer';
import axios, { AxiosError } from 'axios';
import { ErrorResponse } from 'interfaces/api';
import { formatTitle, getBlockNumber, splitBlockNumber } from 'utils';

export const CustomerForm = ({
  customer,
  handleCancel
}: {
  customer?: CustomerData;
  handleCancel: () => void;
}) => {
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState<boolean>(true);
  const [selectedHousing, setSelectedHousing] = useState<HousingType>();
  const [block, setBlock] = useState<string>('');
  const [blockNumber, setBlockNumber] = useState<string>('');
  const { setIsModalOpen } = useModal();
  const [form] = Form.useForm();

  const handleNumberChange = (phoneNum: string) => {
    const reg = /^(^\+62|62|^08)(\d{3,4}-?){2}\d{3,4}$/;
    const validateStatus = reg.test(phoneNum);
    setIsValidPhoneNumber(phoneNum ? validateStatus : true);
  };

  useEffect(() => {
    form.resetFields();
    if (customer) {
      form.setFieldsValue({
        name: customer.name,
        phoneNumber: customer.phoneNumber,
        status: customer.status,
        housing: formatTitle(customer.housing)
      });
      setSelectedHousing(customer.housing);
      const { block, blockNumber } = splitBlockNumber(customer.blockNumber);
      setBlock(block);
      setBlockNumber(blockNumber);
    } else {
      // form.resetFields();
      form.setFieldsValue({
        name: '',
        phoneNumber: '',
        status: '',
        housing: ''
      });
      setBlock('');
      setBlockNumber('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer]);

  const handleChangeSelectedHousing = (value: HousingType) => {
    setSelectedHousing(value);
    setBlockNumber('');
    setBlock('');
  };

  const handleSubmit = async (values: any) => {
    try {
      if (!isValidPhoneNumber) {
        message.error('Nomor telepon tidak valid!');
        return;
      }

      const customerData = {
        ...(customer && { id: customer.id }),
        name: values.name,
        housing: customer?.housing || values.housing,
        phoneNumber: values.phoneNumber ? values.phoneNumber : null,
        status: values.status,
        blockNumber: customer?.blockNumber || getBlockNumber(block, blockNumber)
      };
      await (customer
        ? updateCustomer(customerData)
        : addCustomer({ ...customerData }));
      setIsModalOpen(false);
      customer
        ? message.success('Pelanggan berhasil diubah!')
        : message.success('Pelanggan berhasil ditambahkan!');
      form.resetFields();
      setBlock('');
      setBlockNumber('');
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        const error = err as AxiosError<ErrorResponse>;
        message.error(error.response?.data.message);
      }
    }
  };

  return (
    <Form
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      autoComplete="off"
      requiredMark="optional"
      form={form}
      initialValues={
        customer
          ? {
              name: customer.name,
              phoneNumber: customer.phoneNumber,
              status: customer.status,
              housing: customer.housing
            }
          : undefined
      }
      onFinish={handleSubmit}
    >
      <Form.Item
        className="form-name"
        label="Nama"
        name="name"
        rules={[{ required: true, message: 'Nama tidak boleh kosong!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        className="form-housing"
        label="Perumahan"
        name="housing"
        rules={[{ required: true, message: 'Perumahan tidak boleh kosong!' }]}
      >
        <Select disabled={!!customer} onChange={handleChangeSelectedHousing}>
          {Object.entries(HOUSINGS).map(([housingType, housing]) => (
            <Select.Option key={housingType} value={housingType}>
              {housing.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="Nomor Blok"
        name="blockNumber"
        rules={
          !customer
            ? [{ required: true, message: 'Nomor blok tidak boleh kosong!' }]
            : undefined
        }
      >
        <Space.Compact block>
          <Select
            className="select-before"
            value={block}
            style={{ width: '30%' }}
            onChange={setBlock}
            disabled={!!customer}
          >
            {selectedHousing &&
              HOUSINGS[selectedHousing]?.block.map((value, index) => (
                <Select.Option key={index} value={value}>
                  {value}
                </Select.Option>
              ))}
          </Select>
          <Input
            style={{ width: '70%' }}
            onChange={(e) => setBlockNumber(e.target.value)}
            disabled={!!customer}
            value={blockNumber}
          />
        </Space.Compact>
      </Form.Item>
      <Form.Item
        validateStatus={isValidPhoneNumber ? 'success' : 'error'}
        label="No. Telepon"
        name="phoneNumber"
      >
        <Input onChange={(e) => handleNumberChange(e.target.value)} />
      </Form.Item>
      <Form.Item
        label="Status"
        name="status"
        rules={[{ required: true, message: 'Status tidak boleh kosong' }]}
      >
        <Radio.Group>
          <Radio value="ACTIVE">Aktif</Radio>
          <Radio value="AWAITING_SUSPENSION">Menunggu Penangguhan</Radio>
          <Radio value="INACTIVE">Nonaktif</Radio>
        </Radio.Group>
      </Form.Item>
      <Space
        style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 10 }}
      >
        <Button
          key="back"
          onClick={() => {
            handleCancel();
          }}
        >
          Batal
        </Button>
        <Button type="primary" htmlType="submit">
          Simpan
        </Button>
      </Space>
    </Form>
  );
};
